'use client';

import * as React from 'react';

import * as LabelPrimitive from '@radix-ui/react-label';

import { cn } from '../lib/utils/tailwindClass';

const Label = React.forwardRef<
	React.ElementRef<typeof LabelPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => <LabelPrimitive.Root className={cn('label', className)} ref={ref} {...props} />);

Label.displayName = LabelPrimitive.Root.displayName;

export { Label };

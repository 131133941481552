import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

import type { IFine } from '../../entities/Fine';
import type { IFineGetByBookingParams } from '../../services/FineGetByBookingService';
import { fineGetByBookingService } from '../../services/FineGetByBookingService';
import type { IFineGetByIdParams } from '../../services/FineGetByIdService';
import { fineGetByIdService } from '../../services/FineGetByIdService';
import type { IFineGetByPlateNumberParams } from '../../services/FineGetByPlateNumberService';
import { fineGetByPlateNumberService } from '../../services/FineGetByPlateNumberService';
import type { IFineInsertParams } from '../../services/FineInsertService';
import { fineInsertService } from '../../services/FineInsertService';
import { type IFineModifyParams, fineModifyService } from '../../services/FineModifyService';
import type { IFineSearchParams } from '../../services/FineSearchService';
import { fineSearchService } from '../../services/FineSearchService';

/**
 * Clears the fine data from the state.
 */
export const fineClear = createSyncAction('fleet/fineClear');

/**
 * Searches for fines based on search parameters.
 * @param {IFine[]} payload `IFine[]` The payload for the action.
 * @param {IFineSearchParams} IFineSearchParams The parameters for retrieving fines.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves to an array of fines.
 */
export const fineSearch = createAsyncAction<IFine[], IFineSearchParams>('fleet/fine/search', fineSearchService);

/**
 * Inserts a new fine into the fleet.
 * @param {IFine} payload `IFine` The fine to be inserted.
 * @param {IFineInsertParams} IFineInsertParams The parameters for inserting a new fine.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves when the fine has been inserted.
 */
export const fineInsert = createAsyncAction<IFine, IFineInsertParams>('fleet/fine/insert', fineInsertService);

/**
 * Modifies an existing fine in the fleet.
 * @param {IFine} payload `IFine` The fine to be modified.
 * @param {IFineModifyParams} IFineModifyParams The parameters for modifying an existing fine.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves when the fine has been modified.
 */
export const fineModify = createAsyncAction<IFine, IFineModifyParams>('fleet/fine/modify', fineModifyService);

/**
 * Retrieves fines associated with a specific booking.
 *
 * @param {IFine[]} payload - The fines to be retrieved.
 * @param {IFineGetByBookingParams} params - Parameters to identify fines related to a specific booking.
 * @returns {Promise<void>} - A promise that resolves to an array of fines associated with the booking.
 */
export const fineGetByBooking = createAsyncAction<IFine[], IFineGetByBookingParams>(
	'fine/getByBooking',
	fineGetByBookingService,
);

/**
 * Retrieves fines associated with a specific plate number.
 *
 * @param {IFine[]} payload - The fines to be retrieved.
 * @param {IFineGetByPlateNumberParams} params - Parameters to identify fines related to a specific plate number.
 * @returns {Promise<void>} - A promise that resolves to an array of fines associated with the plate number.
 */
export const fineGetByPlateNumber = createAsyncAction<IFine[], IFineGetByPlateNumberParams>(
	'fine/getByPlateNumber',
	fineGetByPlateNumberService,
);

/**
 * Retrieves a fine by its ID.
 *
 * @param {IFine} payload - The fine to be retrieved.
 * @param {IFineGetByIdParams} params - Parameters to identify the fine by its ID.
 * @returns {Promise<void>} - A promise that resolves to the fine identified by the given ID.
 */
export const fineGetById = createAsyncAction<IFine, IFineGetByIdParams>('fine/getById', fineGetByIdService);

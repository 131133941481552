export enum CreationMethod {
	/**
	 * Used from app request
	 */
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	APP = 1,
	/**
	 * Use only on web request
	 */
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	WEB = 1,
	/**
	 * Use only on XML request
	 */
	XML = 2,
	/**
	 * Use only on OTA request
	 */
	OTA = 5,
	/**
	 * Use only on partners request
	 */
	PARTNERS = 3,
	/**
	 * Used from rac, when the user has the booking permission New External
	 */
	CONTACTCENTER = 0,
	/**
	 * Used from rac, when the user does not have the booking permission New External
	 */
	BRANCH = 4,
}

'use client';

import * as React from 'react';

import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { RadioGroupItem } from './components/RadioGroupItem';
import { cn } from '../lib/utils/tailwindClass';

interface RadioGroupProps extends Omit<React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>, 'disabled'> {
	/**
	 * Whether the radio group should be disabled
	 */
	isDisabled?: boolean;
}

const RadioGroup = React.forwardRef<React.ElementRef<typeof RadioGroupPrimitive.Root>, RadioGroupProps>(
	({ className, isDisabled, ...props }, ref) => {
		return (
			<RadioGroupPrimitive.Root
				className={cn('tw-grid tw-gap-2', className)}
				disabled={isDisabled}
				{...props}
				ref={ref}
			/>
		);
	},
);

RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

export { RadioGroup, RadioGroupItem };

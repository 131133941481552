import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { FineEndPoints } from './FineEndpoints';
import type { IFine } from '../entities/Fine';

export interface IFineGetByPlateNumberParams extends IServiceBaseParams {
	plateNumber: string;
}

/**
 * Get list fine by plate number
 * @param {string} plateNumber
 */
export const fineGetByPlateNumberService = (model: IFineGetByPlateNumberParams): Promise<ServiceResponse<IFine[]>> => {
	return Api.get<IFine[], IFineGetByPlateNumberParams>(FineEndPoints.GET_BY_PLATENUMBER, model);
};

import type { IBranch } from '../../models/entities/Branch';
import type { IVehicleGroupAvailabilityAndPrice } from '../../modules/booking/availability/entities/VehicleGroupAvailabilityAndPrice';
import { getChoosableGroups } from '../booking/BookingVehicleGroups';

/**
 * Check if we can do nearby branches availability
 * @returns boolean
 */
export const canCheckNearbyBranchesAvailability = ({
	vehicleGroupsAvailability,
	pickUpBranch,
}: {
	vehicleGroupsAvailability: IVehicleGroupAvailabilityAndPrice[];
	pickUpBranch?: IBranch;
}) => {
	/**
	 * Only check nearby branches if pickUpBranch has nearby branches
	 */
	if (pickUpBranch?.nearbyBranches.length === 0) {
		return false;
	}

	const groups = getChoosableGroups(vehicleGroupsAvailability);
	const hasVehicleGroups = groups.filter((group) => group.choosable).length > 0;

	/**
	 * Only check nearby branches if there is no vehicle groups
	 */
	if (hasVehicleGroups || !pickUpBranch) {
		return false;
	}

	return true;
};

/**
 * Establish the branch just like the pick up if it is not one way
 * Only use when select nearby branch
 * @param branches All branches
 * @param branch selected branch
 * @param dropOffBranch current DropOffBranch
 * @returns droOff branch codes
 */
export const getDropOffBranchFromNearbyBranchSelected = ({
	branches,
	dropOffBranchCode,
	selectedBranchCode,
	pickUpBranchCode,
}: {
	branches: IBranch[];
	selectedBranchCode?: string;
	pickUpBranchCode?: string;
	dropOffBranchCode?: string;
}) => {
	if ((selectedBranchCode && !dropOffBranchCode) || !branches) {
		return selectedBranchCode;
	}

	const selectedBranch = branches.find((branch) => branch.code === selectedBranchCode);
	const dropOffBranches = branches.filter((branch) => branch.provider === selectedBranch?.provider);
	const dropOffBranch = dropOffBranches.find((branch) => branch.code === dropOffBranchCode);

	if (dropOffBranch && pickUpBranchCode && dropOffBranch.code !== pickUpBranchCode) {
		return dropOffBranchCode;
	}

	if (dropOffBranch && selectedBranch) {
		return selectedBranch.code;
	}

	return undefined;
};

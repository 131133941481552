import React, { useCallback, useEffect, useRef } from 'react';

import type { DefaultValues, FieldValues, Path, UseFormReturn } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';

import { hasValue } from '../field/helpers';

type ResetAction<TFieldValues> = (formValues: TFieldValues) => TFieldValues;
export interface FormType<T extends FieldValues> extends UseFormReturn<T, any, undefined> {
	/** Reset form values */
	// Override reset method to allow clear all form values automatically
	reset: (value?: T | DefaultValues<T> | ResetAction<T>) => void;
	// List of fields to trigger validation when form is mounted
	triggerListRef: React.MutableRefObject<string[]>;
}
export interface IFormProps<T extends FieldValues> {
	/** Unique form identifier */
	id?: string;
	/** Function to execute on form submission */
	onSubmit?: (data: T) => void;
	/** */
	validation?: (form: UseFormReturn<T, any, undefined>) => void;
	/** Form validation mode */
	mode?: 'all' | 'onSubmit' | 'onBlur' | 'onChange' | 'onTouched';
	/** Form reValidateMode */
	reValidateMode?: 'onChange' | 'onBlur' | 'onSubmit';
	/** Initial values for the form */
	initialValues?: DefaultValues<T>;
	/** Form children elements (fields), can be a function receiving form methods or a React node */
	children: ((form: FormType<T>) => React.ReactNode) | React.ReactNode;
	/** Optional CSS classes for the form */
	className?: string;
	/** Optional CSS styles for the form */
	style?: React.CSSProperties;
	/** Optional form encoding type, useful for file uploads */
	encType?: string;
	/* Options auto complete attribute */
	autoComplete?: string;
	/* Optional form methods from useForm hook */
	form?: UseFormReturn<T, any, undefined>;
	/** Disables submit when pressing enter key */
	disableEnterKey?: boolean;
}

/**
 * Form component
 *
 * This component provides a form wrapper using react-hook-form.
 * It initializes the form with given mode and initial values,
 * handles form submission, and ensures form values are reset
 * and cleaned properly before submission.
 *
 * @param id Unique form identifier
 * @param onSubmit Function to execute on form submission
 * @param validation Function to handle additional validation logic outside react-hook-form
 * @param mode Form validation mode (default: 'onChange')
 * @param reValidateMode Form reValidateMode (default: 'onChange')
 * @param initialValues Initial values for the form
 * @param children Form children elements, can be a function receiving form methods or a React node
 * @param className Optional CSS classes for the form
 * @param style Optional CSS styles for the form
 * @param encType Optional form encoding type, useful for file uploads
 * @param autoComplete Options auto complete attribute
 * @param form Optional form methods from useForm hook
 *
 *
 * @returns The form component wrapped with FormProvider
 */
export const Form = <T extends FieldValues>({
	onSubmit,
	validation,
	mode = 'onChange',
	reValidateMode = 'onChange',
	initialValues,
	children,
	form: externalForm,
	disableEnterKey,
	...props
}: IFormProps<T>) => {
	// Create form with mode and defaultValues
	let form = useForm<T>({ mode, reValidateMode, defaultValues: initialValues });
	if (externalForm) {
		form = externalForm;
	}

	const { getValues, watch, formState } = form;

	const triggerListRef = useRef<string[]>([]);

	useEffect(() => {
		// Trigger validation for all fields in the trigger list
		for (const name of triggerListRef.current) {
			form.trigger(name as Path<T>);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const reset = useCallback(
		(defaultValues?: T | DefaultValues<T>) => {
			// Get the current form values
			const values = getValues();

			/*
			 * NOTE: To clear all form values in react-hook-form,
			 * set ALL values to null or an empty string (''), undefined values will not work
			 */
			const resetValues = Object.fromEntries(
				Object.keys(values).map((key) => [key, defaultValues?.[key] ?? null]),
			) as DefaultValues<T>;

			// Reset form values
			form.reset(resetValues);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	useEffect(() => {
		reset(initialValues);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(initialValues)]);

	useEffect(() => {
		if (!validation) {
			return;
		}
		validation(form);
		const subscription = watch(() => {
			validation(form);
		});
		// eslint-disable-next-line consistent-return
		return () => subscription.unsubscribe();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [validation, JSON.stringify(formState.touchedFields)]);

	const handleOnSubmit = (values: T) => {
		if (!onSubmit) {
			return;
		}
		// Remove empty, null, or undefined values from the values object
		Object.keys(values).forEach((key) => {
			if (!hasValue(values[key])) {
				delete values[key];
			}
		});
		onSubmit(values);
	};

	const methods: FormType<T> = {
		...form,
		triggerListRef,
		reset: (value?: T | DefaultValues<T> | ResetAction<T>) =>
			typeof value === 'function' ? reset(value(form.getValues())) : reset(value),
	};

	return (
		<FormProvider {...methods}>
			<form
				{...props}
				noValidate
				onKeyDown={
					disableEnterKey
						? (event) => {
								if (event.key === 'Enter') {
									event.preventDefault();
								}
							}
						: undefined
				}
				onSubmit={form.handleSubmit(handleOnSubmit)}
			>
				{typeof children === 'function' ? children(methods) : children}
			</form>
		</FormProvider>
	);
};

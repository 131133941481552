import * as React from 'react';

import * as SheetPrimitive from '@radix-ui/react-dialog';

import { cn } from '../../lib/utils/tailwindClass';

export const SheetOverlay = React.forwardRef<
	React.ElementRef<typeof SheetPrimitive.Overlay>,
	React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
	<SheetPrimitive.Overlay className={cn('sheet-overlay', className)} {...props} ref={ref} />
));

SheetOverlay.displayName = SheetPrimitive.Overlay.displayName;

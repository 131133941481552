import type { ElementType, FC } from 'react';
import React from 'react';

import { cn } from '../../lib/utils/tailwindClass';
import type { INavItem } from '../types';

interface ISidebarListItemSubmenuProps {
	isMobile?: boolean;
	isMenuOpen: boolean;
	items?: INavItem[];
	AnchorComponent?: ElementType;
}

export const SidebarListItemSubmenu: FC<ISidebarListItemSubmenuProps> = ({
	isMobile,
	isMenuOpen,
	items,
	AnchorComponent,
}) => {
	const shouldRenderItems = (isMobile && isMenuOpen && items) || (!isMobile && items);
	const Comp = AnchorComponent ? AnchorComponent : 'a';

	if (shouldRenderItems) {
		return items.map(({ icon: Icon, url, name, active }) => (
			<Comp
				className={cn('sidebar-list-item-container-children', active && 'sidebar-item-active')}
				href={url}
				key={name + url}
				to={url}
			>
				{Icon ? <Icon className="tw-w-4 tw-h-4" /> : null}
				{name}
			</Comp>
		));
	}

	return null;
};

SidebarListItemSubmenu.displayName = 'SidebarListItemSubmenu';

import type { FC } from 'react';

import { Link } from 'react-router-dom';

import type { IPartner } from '@crac/core/models/entities/Partner';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@crac/ui/dropdown';
import { Icon } from '@crac/ui/icons/Icon';

interface IHeaderDropdownProps {
	partner: IPartner;
}

export const HeaderDropdown: FC<IHeaderDropdownProps> = ({ partner }) => {
	return (
		<DropdownMenu modal={false}>
			<DropdownMenuTrigger className="account-dropdown-trigger">
				<Icon className="tw-w-3 tw-h-3" name="FaUser" size="sm" />
				<span className="account-username">{`${partner.name}(${partner.userName})`.toUpperCase()}</span>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="start" side="bottom" sideOffset={-10}>
				<DropdownMenuItem asChild>
					<Link className="account-dropdown-link" to="/login">
						<Icon className="header-small-icon" name="FaSignOutAlt" size="sm" /> Logout
					</Link>
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

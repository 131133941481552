import type { FC } from 'react';
import { useState } from 'react';

import { Label } from '@crac/ui/label';
import { RadioGroup, RadioGroupItem } from '@crac/ui/radioGroup';

import { BaseComponentValues } from '../../../types/IComponentBaseProps';
interface ILoginSwitchLanguageProps {
	onChange?: (locale: string) => void;
	currentLocale: string;
}

export const LoginSwitchLanguage: FC<ILoginSwitchLanguageProps> = ({
	onChange,
	currentLocale = BaseComponentValues.locale,
}) => {
	const [locale, setLocale] = useState(currentLocale);

	const handleChange = (value: string) => {
		setLocale(value);
		if (onChange) {
			onChange(value);
		}
	};

	return (
		<div className="[&_label]:tw-cursor-pointer tw-flex tw-justify-center tw-py-3 d-flex tw-flex-row justify-content-center py-3 ">
			<RadioGroup className="tw-flex" defaultValue={locale} onValueChange={handleChange} orientation="vertical">
				<div className="tw-flex tw-items-center">
					<RadioGroupItem checked={locale === 'es'} id="languageES" value="es" />
					<Label className="tw-ms-2" htmlFor="languageES">
						Español
					</Label>
				</div>
				<div className="tw-flex tw-items-center">
					<RadioGroupItem checked={locale === 'en'} id="languageEn" value="en" />
					<Label className="tw-ms-2" htmlFor="languageEn">
						English
					</Label>
				</div>
			</RadioGroup>
		</div>
	);
};

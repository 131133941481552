/**
 * @deprecated - use `CustomerEndPoints` from `packages\core\src\modules\customer\services\CustomerEndpoints.ts` instead.
 */
export enum CustomerEndPoints {
	/**
	 * Customer accept gdp
	 * @host `/customer/acceptGdpr/`
	 */
	ACCEPT_GDPR = '/customer/acceptGdpr/',

	/**
	 * Customer assign points
	 * @host `/customer/assignPoints/`
	 */
	ASSIGN_POINTS = '/customer/assignPoints/',

	/**
	 * Customer check can make contract
	 * @host `/customer/customerCanMakeContract/`
	 */
	CAN_MAKE_CONTRACT = '/customer/customerCanMakeContract/',

	/**
	 * Customer get by code
	 * @host `/customer/getByCode/`
	 */
	GET_BY_CODE = '/customer/getByCode/',

	/**
	 * Customer insert
	 * @host `/customer/insert/`
	 */
	INSERT = '/customer/insert/',

	/**
	 * Customer login
	 * @host `/customer/login/`
	 */
	LOGIN = '/customer/login/',

	/**
	 * Customer refresh Token
	 * @host `/customer/refreshToken/`
	 */
	REFRESH_TOKEN = '/customer/refreshToken/',

	/**
	 * Customer modify
	 * @host `/customer/modify/`
	 */
	MODIFY = '/customer/modify/',

	/**
	 * Customer get pendings
	 * @host `/customer/pendings/`
	 */
	PENDINGS = '/customer/pendings/',

	/**
	 * Customer search
	 * @host `/customer/search/`
	 */
	SEARCH = '/customer/search/',

	/**
	 * Customer Validate data by type validation
	 * @host `/customer/validateData/`
	 */
	VALIDATE_DATA = '/customer/validateData/',

	/**
	 * Customer social network login
	 * @host `/customerSocialNetwork/login`
	 */
	SOCIAL_NETWORK_LOGIN = '/customerSocialNetwork/login',
	/**
	 * Customer social network get by customer
	 * @host `/customerSocialNetwork/GetByCustomer`
	 */
	SOCIAL_NETWORK_GET_BY_CUSTOMER = '/customerSocialNetwork/GetByCustomer',
	/**
	 * Customer social network link
	 * @host `/customerSocialNetwork/GetByCustomer`
	 */
	SOCIAL_NETWORK_LINK = '/customerSocialNetwork/link',
	/**
	 * Customer social network unLink
	 * @host `/customerSocialNetwork/unLink`
	 */
	SOCIAL_NETWORK_UNLINK = '/customerSocialNetwork/unLink',
	/**
	 * Customer sign up Newsletter
	 * @host `/customer/signUpNewsLetter`
	 */
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	SUBSCRIBE_TO_NEWSLETTER = '/customer/signUpNewsLetter',
	/**
	 * Customer unsubscribe to the newsletter
	 * @host `/customer/unsubscribeNewsLetter`
	 */
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	UNSUBSCRIBE_TO_NEWSLETTER = '/customer/unsubscribeNewsLetter',
	/**
	 * Check customer can reset password
	 * @host `/customer/canResetPassword`
	 */
	CAN_RESET_PASSWORD = '/customer/canResetPassword',
	/**
	 * Customer send email to reset password
	 * @host `/customer/sendResetPassword`
	 */
	SEND_RESET_PASSWORD = '/customer/sendResetPassword',
	/**
	 * Reset customer password
	 * @host `/customer/passwordReset`
	 */
	RESET_PASSWORD = '/customer/resetPassword',
	/**
	 * Create customer account
	 * @host `/customer/signUp`
	 */
	SIGN_UP = '/customer/signUp',
	/**
	 * Modify customer password
	 * @host `/customer/modifyPassword`
	 */
	MODIFY_PASSWORD = '/customer/modifyPassword',
	/**
	 * Customer  delete
	 * @host `/customer/delete`
	 */
	DELETE = '/customer/delete',
	/**
	 * Email validation
	 */
	EMAIL_VALIDATION = '/customer/validateEmail',

	SIGNUP_FITUR = '/customer/signUpFitur',
}

import { cva } from 'class-variance-authority';

export const sheetVariants = cva('sheet-content', {
	variants: {
		side: {
			top: 'sheet-content-side-top',
			bottom: 'sheet-content-side-bottom',
			left: 'sheet-content-side-left',
			right: 'sheet-content-side-right',
		},
	},
	defaultVariants: {
		side: 'right',
	},
});

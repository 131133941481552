/**
 * @deprecated - use 'CouponEndPoint' from '@crac/core/modules/coupons/services/CouponEndpoints.ts' instead.
 */
export enum CouponEndPoint {
	/**
	 * Validate coupon
	 * @host `/coupon/check`
	 */
	CHECK = '/coupon/Check',
	/**
	 * Coupons by customer code
	 */
	COUPON_GET_BY_CUSTOMER_CODE = '/customerCoupon/GetByCustomer',
	/**
	 * Coupons by booking number
	 */
	COUPON_BY_BOOKING_NUMBER = '/customerCoupon/GetByBooking',
	/**
	 * Insert customer coupon
	 */
	COUPON_INSERT = '/customerCoupon/insert',
	/**
	 * Insert customer coupon
	 */
	COUPON_INSERT_CALLBACK = '/customerCoupon/insertCallback',
}

import * as React from 'react';

import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

import { cn } from '../../lib/utils/tailwindClass';

export const DropdownMenuSeparator = React.forwardRef<
	React.ElementRef<typeof DropdownMenuPrimitive.Separator>,
	React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
>(({ className, ...props }, ref) => (
	<DropdownMenuPrimitive.Separator
		className={cn('-tw-mx-1 tw-my-1 tw-h-px tw-bg-gray-100 dark:tw-bg-gray-500', className)}
		ref={ref}
		{...props}
	/>
));

DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;

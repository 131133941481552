import type { IPaymentLine } from '../../models/entities/PaymentLine';
import type {
	IPaymentLineAddParams,
	IPaymentLineDeleteChargeBackParams,
	IPaymentLineGetByBookingParams,
	IPaymentLineGetByCustomerParams,
	IPaymentLineSearchParams,
	IPaymentLineUpdateChargeBackParams,
} from '../../models/serviceParams/PaymentLineParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { PaymentEndPoints } from '../endPoints/PaymentEndPoints';

/**
 * @deprecated - use 'paymentLineGetByBookingService' from '@crac/core/modules/customer/payment/services/PaymentLineGetByBookingService' instead.
 * GET payment line from booking
 *  {string} bookingNumber booking number
 * @returns {Promise<Array>} `Array<PaymentLine>` Payment line list
 */
export const paymentLineServiceGetByBooking = (
	model: IPaymentLineGetByBookingParams,
): Promise<ServiceResponse<IPaymentLine[]>> => {
	return Api.get<IPaymentLine[], IPaymentLineGetByBookingParams>(PaymentEndPoints.PAYMENT_LINE_GET_BY_BOOKING, model);
};

/**
 * @deprecated - use 'paymentLineGetByCustomerService' from '@crac/core/modules/customer/payment/services/PaymentLineGetByCustomerService' instead.
 * GET payment line from customer
 *  {string} customerCode
 * @returns {Promise<Array>} `Array<PaymentLine>` Payment line list
 */
export const paymentLineServiceGetByCustomer = (
	model: IPaymentLineGetByCustomerParams,
): Promise<ServiceResponse<IPaymentLine[]>> => {
	return Api.get<IPaymentLine[], IPaymentLineGetByCustomerParams>(
		PaymentEndPoints.PAYMENT_LINE_GET_BY_CUSTOMER,
		model,
	);
};

/**
 * @deprecated - use 'paymentLineAddService' from '@crac/core/modules/customer/payment/services/PaymentLineAddService' instead.
 * Add payment line in booking
 *  {string} bookingNumber booking number
 *  {PaymentLine} paymentLine PaymenLine Object
 * @returns {Promise<void>} `void`
 */
export const paymentLineServiceAdd = (model: IPaymentLineAddParams): Promise<ServiceResponse<IPaymentLine>> => {
	return Api.post<IPaymentLine, IPaymentLineAddParams>(PaymentEndPoints.PAYMENT_LINE_ADD, model);
};

/**
 * @deprecated - use 'paymentLineSearchService' from '@crac/core/modules/customer/payment/services/PaymentLineSearchService' instead.
 * Search payment lines
 *  {string} creaditCardNumber
 * @returns {Promise<Array>} `Array<PaymentLine>` Payment line list
 */
export const paymentLineServiceSearch = (model: IPaymentLineSearchParams): Promise<ServiceResponse<IPaymentLine[]>> => {
	return Api.get<IPaymentLine[], IPaymentLineSearchParams>(PaymentEndPoints.PAYMENT_LINE_SEARCH, model);
};

/**
 * @deprecated - use 'paymentLineUpdateChargeBackService' from '@crac/core/modules/customer/payment/services/PaymentLineUpdateChargeBackService' instead.
 */
export const paymentLineServiceUpdateChargeBack = (
	model: IPaymentLineUpdateChargeBackParams,
): Promise<ServiceResponse<IPaymentLine>> => {
	return Api.post<IPaymentLine, IPaymentLineUpdateChargeBackParams>(PaymentEndPoints.UPDATE_CHARGE_BACK, model);
};

/**
 * @deprecated - use 'paymentLineDeleteChargeBackService' from '@crac/core/modules/customer/payment/services/PaymentLineDeleteChargeBackService' instead.
 */
export const paymentLineServiceDeleteChargeBack = (
	model: IPaymentLineDeleteChargeBackParams,
): Promise<ServiceResponse<IPaymentLine>> => {
	return Api.post<IPaymentLine, IPaymentLineUpdateChargeBackParams>(PaymentEndPoints.DELETE_CHARGE_BACK, model);
};

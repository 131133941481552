import * as React from 'react';

import * as SheetPrimitive from '@radix-ui/react-dialog';

import { cn } from '../../lib/utils/tailwindClass';

export const SheetTitle = React.forwardRef<
	React.ElementRef<typeof SheetPrimitive.Title>,
	React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...props }, ref) => (
	<SheetPrimitive.Title className={cn('sheet-title', className)} ref={ref} {...props} />
));

SheetTitle.displayName = SheetPrimitive.Title.displayName;

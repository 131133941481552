import type { IExtraDriver } from '../../models/entities/ExtraDriver';
import type { IExtraDriverGetPrevisionServices, IService } from '../../models/entities/Service';
import type {
	IExtraDriverGetByBookingParams,
	IExtraDriverGetPrevisionServicesParams,
	IExtraDriverGetServicesParams,
	IExtraDriverRemoveParams,
	IExtraDriverSaveParams,
} from '../../models/serviceParams/ExtraDriverParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { ExtraDriverEndPoints } from '../endPoints/ExtraDriverEndPoints';

/**
 * @deprecated Use `extraDriverGetByBookingService` from `modules/booking/extraDriver/services`
 * GetByBooking
 * @param {string} bookingNumber ExtraDriver bookingNumber
 * @return {Promise<ServiceResponse<ExtraDriver[]>>} `Promise<ServiceResponse<ExtraDriver[]>>`
 */
export const extraDriverServiceGetByBooking = (
	model: IExtraDriverGetByBookingParams,
): Promise<ServiceResponse<IExtraDriver[]>> => {
	return Api.get<IExtraDriver[], IExtraDriverGetByBookingParams>(ExtraDriverEndPoints.GET_BY_BOOKING, model);
};

/**
 * @deprecated Use `extraDriverGetPrevisionServicesService` from `modules/booking/extraDriver/services`
 * GetPrevisionServices
 * Fetches a preview of services to be added or removed for an extra driver.
 * It returns details on services that will be added or removed from the booking
 * (`servicesToAdd`, `servicesToRemove`), based on the provided parameters.
 *
 * NOTE: This endpoint was previously named `extraDriver/getServices`.
 * @param {IExtraDriverGetPrevisionServicesParams} model - Contains `bookingNumber`, `extraDriverNumber`,
 * and `birthDate` of the extra driver to be added.
 * @return {Promise<ServiceResponse<ExtraDriver[]>>} - Returns a promise with the list of
 * services to be modified for the specified booking and driver.
 */
export const extraDriverServiceGetPrevisionServices = (
	model: IExtraDriverGetPrevisionServicesParams,
): Promise<ServiceResponse<IExtraDriverGetPrevisionServices>> => {
	return Api.get<IExtraDriverGetPrevisionServices, IExtraDriverGetPrevisionServicesParams>(
		ExtraDriverEndPoints.GET_PREVISION_SERVICES,
		model,
	);
};

/**
 * @deprecated Use `extraDriverSaveService` from `modules/booking/extraDriver/services`
 * Save
 * @return {Promise<ServiceResponse<ExtraDriver>>} `Promise<ServiceResponse<ExtraDriver>>`
 */
export const extraDriverServiceSave = (model: IExtraDriverSaveParams): Promise<ServiceResponse<IExtraDriver[]>> => {
	return Api.post<IExtraDriver[], IExtraDriverSaveParams>(ExtraDriverEndPoints.SAVE, model);
};

/**
 * @deprecated Use `extraDriverRemoveService` from `modules/booking/extraDriver/services`
 * Remove
 * @return {Promise<ServiceResponse<ExtraDriver>>} `Promise<ServiceResponse<ExtraDriver>>`
 */
export const extraDriverServiceRemove = (model: IExtraDriverRemoveParams): Promise<ServiceResponse<IExtraDriver[]>> => {
	return Api.post<IExtraDriver[], IExtraDriverRemoveParams>(ExtraDriverEndPoints.REMOVE, model);
};

/**
 * @deprecated Use `extraDriverGetServicesService` from `modules/booking/extraDriver/services`
 * Return all required services SD, YD, or ED, if it's necessary.
 *
 * NOTE: This endpoint was previously named `extraDriver/getServicesByBooking`.
 * @param {IExtraDriverGetServicesParams} model - Contains the `bookingNumber` for which
 * the services are to be fetched.
 * @return {Promise<ServiceResponse<EService[]>>} - Returns a promise with the list of
 * services required for the specified booking.
 */
export const extraDriverServiceGetServices = (
	model: IExtraDriverGetServicesParams,
): Promise<ServiceResponse<IService[]>> => {
	return Api.get<IService[], IExtraDriverGetServicesParams>(ExtraDriverEndPoints.GET_SERVICES, model);
};

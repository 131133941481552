import type { IComment } from '../../models/entities/Comment';
import type {
	ICommentAddToBookingParams,
	ICommentAddToCompanyParams,
	ICommentAddToCustomerParams,
	ICommentAddToVehicleParams,
	ICommentGetByBookingParams,
	ICommentGetByCompanyParams,
	ICommentGetByCustomerParams,
	ICommentGetByVehicleParams,
	ICommentRemoveFromBookingParams,
	ICommentRemoveFromCompanyParams,
	ICommentRemoveFromCustomerParams,
	ICommentRemoveFromVehicleParams,
} from '../../models/serviceParams/CommentParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { CommentEndPoints } from '../endPoints/CommentEndPoints';

/**
 * @deprecated - use 'commentGetByCustomerService' from 'packages\core\src\modules\comments\services\CommentGetByCustomerService.ts' instead.
 * Get comments by customer code
 *  {string} customerCode Customer code
 * @return {Promise} `Promise<Array<CommentType>>` List comment
 */
export const commentServiceGetByCustomer = (
	model: ICommentGetByCustomerParams,
): Promise<ServiceResponse<IComment[]>> => {
	return Api.get<IComment[], ICommentGetByCustomerParams>(CommentEndPoints.GET_BY_CUSTOMER, model);
};

/**
 * @deprecated - use 'commentGetByCompanyService' from 'packages\core\src\modules\comments\services\CommentGetByCompanyService.ts' instead.
 * Get comments by company code
 *  {string} companyCode Company code
 * @return {Promise} `Promise<Array<CommentType>>` List comment
 */
export const commentServiceGetByCompany = (model: ICommentGetByCompanyParams): Promise<ServiceResponse<IComment[]>> => {
	return Api.get<IComment[], ICommentGetByCompanyParams>(CommentEndPoints.GET_BY_COMPANY, model);
};

/**
 * @deprecated - use 'commentGetByBookingService' from 'packages\core\src\modules\comments\services\CommentGetByBookingService.ts' instead.
 * Get comment by booking number
 *  {string} bookingNumber Booking Number+
 * @return {Promise} `Promise<Array<CommentType>>` List comment
 */
export const commentServiceGetByBooking = (model: ICommentGetByBookingParams): Promise<ServiceResponse<IComment[]>> => {
	return Api.get<IComment[], ICommentGetByBookingParams>(CommentEndPoints.GET_BY_BOOKING, model);
};

/**
 * @deprecated - use 'commentGetByVehicleService' from 'packages\core\src\modules\comments\services\CommentAddToVehicleService.ts' instead.
 * Get comment by plate number
 *  {string} plateNumber Plate Number
 * @return {Promise} `Promise<Array<CommentType>>` List comment
 */
export const commentServiceGetByVehicle = (model: ICommentGetByVehicleParams): Promise<ServiceResponse<IComment[]>> => {
	return Api.get<IComment[], ICommentGetByVehicleParams>(CommentEndPoints.GET_BY_VEHICLE, model);
};

/**
 * @deprecated - use 'commentAddToVehicleService' from 'packages\core\src\modules\comments\services\CommentAddToVehicleService.ts' instead.
 * Add comment to vehicle
 *  {string} message Comment message
 *  {string} code Plate number
 */
export const commentServiceAddToVehicle = (model: ICommentAddToVehicleParams): Promise<ServiceResponse<IComment>> => {
	return Api.post<IComment, ICommentAddToVehicleParams>(CommentEndPoints.ADD_TO_VEHICLE, model);
};

/**
 * @deprecated - use 'commentRemoveFromVehicleService' from 'packages\core\src\modules\comments\services\CommentRemoveFromVehicleService.ts' instead.
 * Remove comment from vehicle
 *  {string} lineNumber Line number
 *  {string} code Plate number
 */
export const commentServiceRemoveFromVehicle = (
	model: ICommentRemoveFromVehicleParams,
): Promise<ServiceResponse<IComment>> => {
	return Api.post<IComment, ICommentRemoveFromVehicleParams>(CommentEndPoints.REMOVE_FROM_VEHICLE, model);
};

/**
 * @deprecated - use 'commentAddToCustomerService' from 'packages\core\src\modules\comments\services\CommentAddToCustomerService.ts' instead.
 * Add comment to customer
 *  {string} message Comment message
 *  {string} code Customer code
 */
export const commentServiceAddToCustomer = (model: ICommentAddToCustomerParams): Promise<ServiceResponse<IComment>> => {
	return Api.post<IComment, ICommentAddToCustomerParams>(CommentEndPoints.ADD_TO_CUSTOMER, model);
};

/**
 * @deprecated - use 'commentRemoveFromCustomerService' from 'packages\core\src\modules\comments\services\CommentRemoveFromCustomerService.ts' instead.
 * Remove comment from customer
 *  {string} lineNumber Line number
 *  {string} code Customer code
 */
export const commentServiceRemoveFromCustomer = (
	model: ICommentRemoveFromCustomerParams,
): Promise<ServiceResponse<IComment>> => {
	return Api.post<IComment, ICommentRemoveFromCustomerParams>(CommentEndPoints.REMOVE_FROM_CUSTOMER, model);
};

/**
 * @deprecated - use 'commentAddToCompanyService' from 'packages\core\src\modules\comments\services\CommentAddToCompanyService.ts' instead.
 * Add comment to company
 *  {string} message Comment message
 *  {string} code Company code
 */
export const commentServiceAddToCompany = (model: ICommentAddToCompanyParams): Promise<ServiceResponse<IComment>> => {
	return Api.post<IComment, ICommentAddToCompanyParams>(CommentEndPoints.ADD_TO_COMPANY, model);
};

/**
 * @deprecated - use 'commentRemoveFromCompanyService' from 'packages\core\src\modules\comments\services\CommentRemoveFromCompanyService.ts' instead.
 * Remove comment from company
 *  {string} lineNumber Line number
 *  {string} code Company code
 */
export const commentServiceRemoveFromCompany = (
	model: ICommentRemoveFromCompanyParams,
): Promise<ServiceResponse<IComment>> => {
	return Api.post<IComment, ICommentRemoveFromCompanyParams>(CommentEndPoints.REMOVE_FROM_COMPANY, model);
};

/**
 * @deprecated - use 'commentAddToBookingService' from 'packages\core\src\modules\comments\services\CommentAddToBookingService.ts' instead.
 * Add comment to booking
 *  {string} message Comment message
 *  {string} code Booking code
 */
export const commentServiceAddToBooking = (model: ICommentAddToBookingParams): Promise<ServiceResponse<IComment>> => {
	return Api.post<IComment, ICommentAddToBookingParams>(CommentEndPoints.ADD_TO_BOOKING, model);
};

/**
 * @deprecated - use 'commentRemoveFromBookingService' from 'packages\core\src\modules\comments\services\CommentRemoveFromBookingService.ts' instead.
 * Remove comment from booking
 *  {string} lineNumber Line number
 *  {string} code Booking code
 */
export const commentServiceRemoveFromBooking = (
	model: ICommentRemoveFromBookingParams,
): Promise<ServiceResponse<IComment>> => {
	return Api.post<IComment, ICommentRemoveFromBookingParams>(CommentEndPoints.REMOVE_FROM_BOOKING, model);
};

/* eslint-disable react/require-optimization */
import type { FC } from 'react';

import { NavLink } from 'react-router-dom';

import type { IPartner } from '@crac/core/models/entities/Partner';
import { Header } from '@crac/ui/header';
import type { INavItem } from '@crac/ui/sidebar';

import { HeaderDropdown } from './HeaderDropdown';
import { LanguageSwitch } from './LanguageSwtch';

interface IHeaderProps {
	partner: IPartner;
	locale?: string;
	menuItems: INavItem[];
	sidebarOpen: boolean;
	mobileSidebarOpen?: boolean;
	isDevelopment: boolean;
	setMobileSidebarOpen?: (open: boolean) => void;
	setLocale?: (locale: string) => void;
}

export const AppHeader: FC<IHeaderProps> = ({
	partner,
	setLocale,
	locale,
	sidebarOpen,
	isDevelopment,
	menuItems,
	mobileSidebarOpen,
	setMobileSidebarOpen,
}): JSX.Element => (
	<Header
		AnchorComponent={NavLink}
		className={isDevelopment ? 'tw-top-[34px]' : ''}
		menuItems={menuItems}
		mobileSidebarOpen={mobileSidebarOpen}
		setMobileSidebarOpen={setMobileSidebarOpen}
		sidebarOpen={sidebarOpen}
	>
		{/* User dropdown */}
		<HeaderDropdown partner={partner} />

		{/* Lang switch */}
		<LanguageSwitch locale={locale} setLocale={setLocale} />
	</Header>
);

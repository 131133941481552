import { FineEndPoints } from './FineEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IFine } from '../entities/Fine';
import type { FineFile } from '../types/FineFileType';

export interface IFineInsertParams extends IServiceBaseParams {
	plateNumber: string;
	dateTime: string | Date;
	fineReason: number;
	fineTransmitter: string;
	expedient: string;
	toPay?: boolean;
	fineAmountToPayCentauro?: number;
	comment?: string;
	files?: FineFile[];
}

/**
 * Insert a fine
 * @return {Promise<FineType>} `FineType`
 */
export const fineInsertService = (model: IFineInsertParams): Promise<ServiceResponse<IFine>> => {
	return Api.post<IFine, IFineInsertParams>(FineEndPoints.INSERT, model);
};

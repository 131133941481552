import { createAction } from '@reduxjs/toolkit';

import { createAsyncAction } from '../../../../shared/state/createAsyncAction';
import type { IBookingLine } from '../../entities/BookingLine';
import type { IBookingLineDeleteParams } from '../../services/BookingLineDeleteService';
import { bookingLineDeleteService } from '../../services/BookingLineDeleteService';
import type { IBookingLineGetByBookingParams } from '../../services/BookingLineGetByBookingService';
import { bookingLineGetByBookingService } from '../../services/BookingLineGetByBookingService';
import type { IBookingLineInsertListParams } from '../../services/BookingLineInsertService';
import { bookingLineInsertService } from '../../services/BookingLineInsertService';
import type { IBookingLineListModifyAmountParams } from '../../services/BookingLineModifyAmountService';
import { bookingLineModifyAmountService } from '../../services/BookingLineModifyAmountService';
import type { IBookingLineListModifyParams } from '../../services/BookingLineModifyService';
import { bookingLineModifyService } from '../../services/BookingLineModifyService';

/**
 * Inserts new booking lines.
 *
 * @param {IBookingLine[]} payload - The booking lines to be inserted.
 * @param {IBookingLineInsertListParams} params - Parameters for the insertion of booking lines.
 * @returns {Promise<void>} - A promise that resolves after the booking lines are inserted.
 */
export const bookingLineInsert = createAsyncAction<IBookingLine[], IBookingLineInsertListParams>(
	'bookingLine/insert',
	bookingLineInsertService,
);

/**
 * Modifies existing booking lines.
 *
 * @param {IBookingLine[]} payload - The booking lines to be modified.
 * @param {IBookingLineListModifyParams} params - Parameters for the modification of booking lines.
 * @returns {Promise<void>} - A promise that resolves after the booking lines are modified.
 */
export const bookingLineModify = createAsyncAction<IBookingLine[], IBookingLineListModifyParams>(
	'bookingLine/modify',
	bookingLineModifyService,
);

/**
 * Deletes a specific booking line.
 *
 * @param {number} payload - The ID of the booking line to be deleted.
 * @param {IBookingLineDeleteParams} params - Parameters for the deletion of the booking line.
 * @returns {Promise<void>} - A promise that resolves after the booking line is deleted.
 */
export const bookingLineDelete = createAsyncAction<number, IBookingLineDeleteParams>(
	'bookingLine/delete',
	bookingLineDeleteService,
);

/**
 * Retrieves booking lines associated with a specific booking.
 *
 * @param {IBookingLine[]} payload - The booking lines to be retrieved.
 * @param {IBookingLineGetByBookingParams} params - Parameters to identify the booking lines associated with a booking.
 * @returns {Promise<void>} - A promise that resolves to an array of booking lines.
 */
export const bookingLineGetByBooking = createAsyncAction<IBookingLine[], IBookingLineGetByBookingParams>(
	'bookingLine/getByBooking',
	bookingLineGetByBookingService,
);

/**
 * Modifies booking lines amounts
 *
 * @param {IBookingLine[]} payload - The booking lines to be modified.
 * @param {IBookingLineListModifyAmountParams} params - Parameters to modify the amounts
 * @returns {Promise<void>} - A promise that resolves after the booking lines are modified.
 */
export const bookingLineModifyAmount = createAsyncAction<IBookingLine[], IBookingLineListModifyAmountParams>(
	'bookingLine/admonModify',
	bookingLineModifyAmountService,
);

/**
 * Clears errors in booking line state.
 */
export const bookingLineClearErrors = createAction('bookingLine/clearErrors');

/**
 * Clears the booking line state.
 */
export const bookingLineClear = createAction('bookingLine/clear');

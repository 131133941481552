/**
 * @deprecated Use `EmailEndpoints` from `modules/email/services`
 */
export enum EmailEndPoints {
	/**
	 * Email send document
	 * @host `/email/senddocument/`
	 */
	SEND_DOCUMENT = '/email/senddocument/',

	/**
	 * Email send feedback
	 * @host `/email/sendfeedback/`
	 */
	SEND_FEEDBACK = '/email/sendfeedback',

	/**
	 * Email send booking to agency
	 * @host `/email/sendbookingagency/`
	 */
	SEND_BOOKING_AGENCY = '/email/sendbookingagency',

	/**
	 * Email send coupon by customer code
	 * @host `/email/sendcouponbycustomercode/`
	 */
	SEND_COUPON_BY_CUSTOMER_CODE = '/email/sendcouponbycustomercode',

	/**
	 * Email send contact form buy car
	 * @host `/email/sendcontactformbuycar/`
	 */
	SEND_CONTACT_FORM_BUY_CAR = '/email/sendcontactformbuycar',
	/**
	 * Email send contact form business
	 *  @host `/email/sendContactFormBusiness/`
	 */
	SEND_CONTACT_FORM_BUSINESS = '/email/sendContactFormBusiness',
	SEND_FITUR_FORM = '/email/sendfiturForm',
	/**
	 * Email send contact form franchise
	 *  @host `/email/sendContactFormFranchise/`
	 */
	SEND_CONTACT_FORM_FRANCHISE = '/email/sendContactFormFranchise',
}

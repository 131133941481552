'use client';

import * as SheetPrimitive from '@radix-ui/react-dialog';

import { SheetContent } from './components/SheetContent';
import { SheetDescription } from './components/SheetDescription';
import { SheetFooter } from './components/SheetFooter';
import { SheetHeader } from './components/SheetHeader';
import { SheetTitle } from './components/SheetTitle';

const Sheet = SheetPrimitive.Root;

const SheetTrigger = SheetPrimitive.Trigger;

const SheetClose = SheetPrimitive.Close;

export { Sheet, SheetTrigger, SheetClose, SheetContent, SheetHeader, SheetFooter, SheetTitle, SheetDescription };

'use client';

import React from 'react';

import { LuChevronRight } from 'react-icons/lu';

import { SidebarListItem } from './components/SidebarListItem';
import type { ISidebarProps } from './types';
import { Button } from '../button/Button';
import { cn } from '../lib/utils/tailwindClass';

const Sidebar = React.forwardRef<HTMLElement, ISidebarProps>(
	({ className, menuItems, AnchorComponent, sidebarOpen, setSidebarOpen, renderSidebarHeader }, ref) => {
		return (
			<nav className={cn('tailwind-sidebar', sidebarOpen && 'tw-w-[200px]', className)} ref={ref}>
				<div className="sidebar-container">
					{renderSidebarHeader ? renderSidebarHeader() : null}
					<div
						className={cn(
							'sidebar-list-container',
							sidebarOpen && '!tw-overflow-y-auto !tw-overflow-x-hidden',
						)}
					>
						<ul className="sidebar-list">
							{menuItems.map((menuItem) => (
								<SidebarListItem
									{...menuItem}
									AnchorComponent={AnchorComponent}
									expandSidebar={sidebarOpen}
									key={menuItem.name + menuItem.url}
								/>
							))}
						</ul>
					</div>
				</div>
				<Button
					className="sidebar-open-btn"
					color="light"
					onClick={() => setSidebarOpen && setSidebarOpen(!sidebarOpen)}
				>
					<LuChevronRight
						className={cn('sidebar-open-btn-icon', sidebarOpen ? '-tw-rotate-180' : 'tw-rotate-0')}
					/>
				</Button>
			</nav>
		);
	},
);

Sidebar.displayName = 'Sidebar';

export { Sidebar };

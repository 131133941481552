import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { FineEndPoints } from './FineEndpoints';
import type { IFine } from '../entities/Fine';

export interface IFineGetByBookingParams extends IServiceBaseParams {
	bookingNumber: string;
}

/**
 * Get list fine by booking number
 * @param {string} bookingNumber
 */
export const fineGetByBookingService = (model: IFineGetByBookingParams): Promise<ServiceResponse<IFine[]>> => {
	return Api.get<IFine[], IFineGetByBookingParams>(FineEndPoints.GET_BY_BOOKING, model);
};

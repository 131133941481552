import * as React from 'react';

import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { LuCircle } from 'react-icons/lu';

import { cn } from '../../lib/utils/tailwindClass';

export const RadioGroupItem = React.forwardRef<
	React.ElementRef<typeof RadioGroupPrimitive.Item>,
	React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, ...props }, ref) => {
	return (
		<RadioGroupPrimitive.Item className={cn('tw-peer radio-item', className)} ref={ref} {...props}>
			<RadioGroupPrimitive.Indicator className="radio-indicator">
				<LuCircle className="tw-h-2.5 tw-w-2.5 tw-fill-current tw-text-current" />
			</RadioGroupPrimitive.Indicator>
		</RadioGroupPrimitive.Item>
	);
});

RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

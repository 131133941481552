import type { ICompany } from '../../models/entities/Company';
import type {
	ICompanyGetByCodeParams,
	ICompanyInsertParams,
	ICompanyModifyParams,
	ICompanySearchParams,
	ICompanyValidateDataParams,
} from '../../models/serviceParams/CompanyParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { CompanyEndPoints } from '../endPoints/CompanyEndPoints';

/**
 * Search company
 *  {string} code Company code
 *  {string} name Company name
 *  {string} taxIdentificationNumber Company tax identification number
 *  {string} country Company country
 * @returns {Promise} `CompanyPropType`
 */
export const companyServiceSearch = (model: ICompanySearchParams): Promise<ServiceResponse<ICompany[]>> => {
	return Api.get<ICompany[], ICompanySearchParams>(CompanyEndPoints.SEARCH, model);
};

/**
 * Search company by code
 *  {string} code Company code
 * @return {Promise} `CompanyPropType` Return company
 */
export const companyServiceGetByCode = (model: ICompanyGetByCodeParams): Promise<ServiceResponse<ICompany>> => {
	return Api.get<ICompany, ICompanyGetByCodeParams>(CompanyEndPoints.GET_BY_CODE, model);
};

/**
 * @deprecated - use 'companyModifyService' from 'packages\core\src\modules\company\services\CompanyModifyService.ts' instead.
 * Modify company
 *  {Company} company Company object
 * @returns {Promise} `CompanyPropType` Updated company
 */
export const companyServiceModify = (model: ICompanyModifyParams): Promise<ServiceResponse<ICompany>> => {
	return Api.post<ICompany, ICompanyModifyParams>(CompanyEndPoints.MODIFY, model);
};

/**
 * @deprecated - use 'companyInsertService' from 'packages\core\src\modules\company\services\CompanyInsertService.ts' instead.
 * Insert company
 *  {Company} company Company object
 * @returns {Promise} `CompanyPropType` Inserted company
 */
export const companyServiceInsert = (model: ICompanyInsertParams): Promise<ServiceResponse<ICompany>> => {
	return Api.post<ICompany, ICompanyInsertParams>(CompanyEndPoints.INSERT, model);
};

/**
 * @deprecated - use 'companyValidateDataService' from 'packages\core\src\modules\company\services\CompanyValidateDataService.ts' instead.
 * Validate company data by Validation type
 * @param model ICompanyValidateDataParams
 */
export const companyServiceValidateData = (model: ICompanyValidateDataParams): Promise<ServiceResponse<boolean>> => {
	return Api.get<boolean, ICompanyValidateDataParams>(CompanyEndPoints.VALIDATE_DATA, model);
};

/**
 * @deprecated - use 'CommentEndPoints' from 'packages\core\src\modules\comments\services\CommentsEndpoints.ts' instead.
 */
export enum CommentEndPoints {
	/**
	 * Get all comments by customer
	 * @host `/comment/getbycustomer/`
	 */
	GET_BY_CUSTOMER = '/comment/getbycustomer/',

	/**
	 * Get all comments by company
	 * @host `/comment/getbycompany/`
	 */
	GET_BY_COMPANY = '/comment/getbycompany/',

	/**
	 * Get all comments by booking
	 * @host `/comment/getbybooking/`
	 */
	GET_BY_BOOKING = '/comment/getbybooking/',

	/**
	 * Get all comments by vehicle
	 * @host `/comment/getbyvehicle/`
	 */
	GET_BY_VEHICLE = '/comment/getbyvehicle/',

	/**
	 * Add comment in vehicle
	 * @host `/comment/addtovehicle/`
	 */
	ADD_TO_VEHICLE = '/comment/addtovehicle/',

	/**
	 * Add comment in booking
	 * @host `/comment/addtobooking/`
	 */
	ADD_TO_BOOKING = '/comment/addtobooking/',

	/**
	 * Add comment in customer
	 * @host `/comment/addtocustomer/`
	 */
	ADD_TO_CUSTOMER = '/comment/addtocustomer/',

	/**
	 * Add comment in company
	 * @host `/comment/addtocompany/`
	 */
	ADD_TO_COMPANY = '/comment/addtocompany/',

	/**
	 * Remove comment from vehicle
	 * @host /comment/removefromvehicle/`
	 */
	REMOVE_FROM_VEHICLE = '/comment/removefromvehicle/',

	/**
	 * Remove comment from customer
	 * @host `/comment/removefromcustomer/`
	 */
	REMOVE_FROM_CUSTOMER = '/comment/removefromcustomer/',

	/**
	 * Remove comment from company
	 * @host `/comment/removefromcompany/`
	 */
	REMOVE_FROM_COMPANY = '/comment/removefromcompany/',

	/**
	 * Remove comment from booking
	 * @host `/comment/removefrombooking/`
	 */
	REMOVE_FROM_BOOKING = '/comment/removefrombooking/',
}

export enum BookingLineEndPoints {
	/**
	 * Booking line insert
	 * @host `/bookingLine/insert/`
	 */
	INSERT = '/bookingLine/insert/',

	/**
	 * BookingLine modify
	 * @host `/bookingLine/modify/`
	 */
	MODIFY = '/bookingLine/modify/',

	/**
	 * Booking line delete
	 * @host `/bookingLine/remove/`
	 */
	DELETE = '/bookingLine/remove/',

	/**
	 * Get booking lines
	 * @host `/bookingLine/getByBooking/`
	 */
	GET_BY_BOOKING = '/bookingLine/getByBooking/',

	/**
	 * Get booking lines
	 * @host `/bookingLine/modifyAmount/`
	 */
	MODIFY_AMOUNT = '/bookingLine/modifyAmount/',
}

export const ColorVariantType = {
	Danger: 'danger',
	Info: 'info',
	Light: 'light',
	Link: 'link',
	Outline: 'outline',
	Primary: 'primary',
	Secondary: 'secondary',
	Success: 'success',
	Warning: 'warning',
	Dark: 'dark',
} as const;

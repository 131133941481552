import * as React from 'react';

import * as SheetPrimitive from '@radix-ui/react-dialog';

import { cn } from '../../lib/utils/tailwindClass';

export const SheetDescription = React.forwardRef<
	React.ElementRef<typeof SheetPrimitive.Description>,
	React.ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
>(({ className, ...props }, ref) => (
	<SheetPrimitive.Description className={cn('sheet-description', className)} ref={ref} {...props} />
));

SheetDescription.displayName = SheetPrimitive.Description.displayName;

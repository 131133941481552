import * as React from 'react';

import { CardContent } from './components/CardContent';
import { CardDescription } from './components/CardDescription';
import { CardFooter } from './components/CardFooter';
import { CardHeader } from './components/CardHeader';
import { CardTitle } from './components/CardTitle';
import { cn } from '../lib/utils/tailwindClass';

const Card = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => (
	<div className={cn('tw-card', className)} ref={ref} {...props} />
));

Card.displayName = 'Card';

export { Card, CardHeader, CardFooter, CardTitle, CardDescription, CardContent };

import type { FC } from 'react';
import { useEffect } from 'react';

import { ComponentConsumersType } from '@crac/core/models/types/ComponentConsumersType';
import { API_ENDPOINT, isProduction } from '@crac/core/modules/shared/api/ApiEndPoint';
import { Icon } from '@crac/ui/icons/Icon';
import { cn } from '@crac/ui/lib/utils/tailwindClass';

import type { IBaseComponentProps } from '../../../types/IComponentBaseProps';
import { ReactGenericPortal } from '../genericPortal';

interface IDevelopToolbarProps extends IBaseComponentProps {
	portalId?: string;
	className?: string;
}

export const DevelopToolbar: FC<IDevelopToolbarProps> = ({ portalId, className, consumer }) => {
	const APIENDPOINT = API_ENDPOINT.replace('https://', '').replace('/api', '');
	useEffect(() => {
		if (consumer === ComponentConsumersType.PARTNER) {
			document.body.style.paddingTop = isProduction ? '0px' : '34px';
		}
	}, [consumer]);

	if (isProduction) {
		return null;
	}

	const text = isProduction
		? `Currently API requests go to the PRODUCTION environment- ${APIENDPOINT}`
		: `Currently API requests go to the DEVELOPMENT environment - ${APIENDPOINT}`;

	const renderToolBar = (
		<div
			className={cn(
				'tw-w-full tw-h-[34px] tw-flex tw-justify-center tw-items-center tw-bg-red-600 tw-text-white tw-fixed tw-top-0 tw-z-50 tw-text-xs',
				className,
			)}
			data-testid="developToolbar"
		>
			<Icon className="tw-mr-1" name={isProduction ? 'FaInfoCircle' : 'FaFlask'} /> {text}
		</div>
	);

	if (portalId) {
		return <ReactGenericPortal portalId={portalId}>{renderToolBar}</ReactGenericPortal>;
	}

	return renderToolBar;
};

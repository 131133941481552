import * as React from 'react';

import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

import { cn } from '../../lib/utils/tailwindClass';

export const DropdownMenuSubContent = React.forwardRef<
	React.ElementRef<typeof DropdownMenuPrimitive.SubContent>,
	React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubContent>
>(({ className, ...props }, ref) => (
	<DropdownMenuPrimitive.SubContent className={cn('tw-dropdown-subcontent', className)} ref={ref} {...props} />
));

DropdownMenuSubContent.displayName = DropdownMenuPrimitive.SubContent.displayName;

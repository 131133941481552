import { cva } from 'class-variance-authority';

import { ColorVariantType, SizeVariantType } from '../lib/types';

export const buttonVariants = cva('tw-btn', {
	variants: {
		variant: {
			[ColorVariantType.Primary]: 'tw-btn-primary tw-text-white',
			[ColorVariantType.Secondary]: 'tw-btn-secondary tw-text-black',
			[ColorVariantType.Success]: 'tw-btn-success tw-text-white',
			[ColorVariantType.Info]: 'tw-btn-info tw-text-black',
			[ColorVariantType.Warning]: 'tw-btn-warning tw-text-black',
			[ColorVariantType.Danger]: 'tw-btn-danger tw-text-white',
			[ColorVariantType.Light]: 'tw-btn-light tw-text-black',
			[ColorVariantType.Link]: 'tw-btn-link tw-text-primary',
			/*
			 * [ColorVariantType.Outline]:
			 * 	'tw-border tw-border-zinc-200 tw-bg-white hover:tw-bg-zinc-100 hover:tw-text-zinc-900 dark:tw-border-zinc-800 dark:tw-bg-zinc-950 dark:hover:tw-bg-zinc-800 dark:hover:tw-text-zinc-50',
			 */
		},
		size: {
			[SizeVariantType.default]: 'tw-btn-default',
			[SizeVariantType.sm]: 'tw-btn-sm',
			[SizeVariantType.lg]: 'tw-btn-lg',
			[SizeVariantType.auto]: 'tw-btn-auto',
		},
	},
	defaultVariants: {
		variant: ColorVariantType.Primary,
		size: SizeVariantType.auto,
	},
});

import React from 'react';

import { cn } from '../lib/utils/tailwindClass';

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
	fluid?: boolean;
	tag?: React.ElementType;
}

const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
	({ children, className, fluid, tag: Tag = 'div', ...props }, ref) => {
		return (
			<Tag
				className={cn(
					'tw-w-full tw-px-3 tw-mx-auto',
					!fluid &&
						'sm:tw-max-w-[540px] md:tw-max-w-[720px] lg:tw-max-w-[960px] xl:tw-max-w-[1140px] xxl:tw-max-w-[1320px]',
					className,
				)}
				ref={ref}
				{...props}
			>
				{children}
			</Tag>
		);
	},
);

Container.displayName = 'Container';

export { Container };

import React from 'react';

import { PermissionsType } from '@crac/core/models/types/PermissionsType';

import type { IRouteConfigType } from '~/types/RouteConfigType';

import { routesIdMap } from './routesIdMap';

const Dashboard = React.lazy(() => import('~/pages/Dashboard/index'));

export const commonRoutes: { [key: number]: IRouteConfigType } = {};

commonRoutes[routesIdMap.Dashboard] = {
	component: Dashboard,
	exact: true,
	id: routesIdMap.Dashboard,
	name: 'Home',
	path: '/dashboard',
	permissions: [PermissionsType.Access],
};

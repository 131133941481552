import { useEffect, useState } from 'react';

import type { Location } from 'react-router-dom';

export const useLayoutState = (location: Location<any>) => {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);

	useEffect(() => {
		setMobileSidebarOpen(false);
	}, [location.pathname]);

	useEffect(() => {
		const onWindowResize = () => {
			setSidebarOpen(false);
			setMobileSidebarOpen(false);
		};

		window.addEventListener('resize', onWindowResize);

		return () => {
			window.removeEventListener('resize', onWindowResize);
		};
	}, []);

	return {
		sidebarOpen,
		setSidebarOpen,
		mobileSidebarOpen,
		setMobileSidebarOpen,
	};
};

import { extendTailwindMerge } from 'tailwind-merge';

export const twMerge = extendTailwindMerge({
	prefix: 'tw-',
	extend: {
		theme: {
			colors: [
				'primary',
				'secondary',
				'success',
				'info',
				'warning',
				'danger',
				'light',
				'dark',
				'ring',
				'background',
				'foreground',
				'muted',
				'brand',
			],
		},
	},
});

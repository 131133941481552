import * as React from 'react';

import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { LuChevronRight } from 'react-icons/lu';

import { cn } from '../../lib/utils/tailwindClass';
import type { DropdownMenuSubTriggerProps } from '../types';

export const DropdownMenuSubTrigger = React.forwardRef<
	React.ElementRef<typeof DropdownMenuPrimitive.SubTrigger>,
	DropdownMenuSubTriggerProps
>(({ className, inset, children, ...props }, ref) => (
	<DropdownMenuPrimitive.SubTrigger
		className={cn('tw-dropdown-subtrigger', inset && 'tw-pl-8', className)}
		ref={ref}
		{...props}
	>
		{children}
		<LuChevronRight className="tw-ml-auto tw-h-4 tw-w-4" />
	</DropdownMenuPrimitive.SubTrigger>
));

DropdownMenuSubTrigger.displayName = DropdownMenuPrimitive.SubTrigger.displayName;

import * as React from 'react';

import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

import { cn } from '../../lib/utils/tailwindClass';
import type { DropdownMenuItemProps } from '../types';

export const DropdownMenuItem = React.forwardRef<
	React.ElementRef<typeof DropdownMenuPrimitive.Item>,
	DropdownMenuItemProps
>(({ className, inset, ...props }, ref) => (
	<DropdownMenuPrimitive.Item
		className={cn('tw-dropdown-item', inset && 'tw-pl-8', className)}
		ref={ref}
		{...props}
	/>
));

DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;

import { cityServiceGetByCountryAndPostCode, cityServiceGetByProvince } from './CityService';
import { commentServiceAddToCustomer } from './CommentService';
import { provinceServiceGetByCountry, provinceServiceGetByCountryAndPostCode } from './ProvinceService';
import type { ICity } from '../../models/entities/City';
import type { ICustomer } from '../../models/entities/Customer';
import type { ICustomerPending } from '../../models/entities/CustomerPending';
import type { IProvince } from '../../models/entities/Province';
import type { ISocialNetWorkLink } from '../../models/entities/SocialNetworkLink';
import type {
	ICustomerAcceptGdprParams,
	ICustomerAssignPointsParams,
	ICustomerBlockParams,
	ICustomerCanMakeContractParams,
	ICustomerCanResetPasswordParams,
	ICustomerDeleteParams,
	ICustomerEmailValidationParams,
	ICustomerGetByCodeParams,
	ICustomerGetDetailData,
	ICustomerGetPendingsParams,
	ICustomerLoginParams,
	ICustomerLoginWithSocialNetworkParams,
	ICustomerModifyDuplicateTaxIdParams,
	ICustomerModifyPasswordParams,
	ICustomerRefreshTokenParams,
	ICustomerResetPasswordParams,
	ICustomerSaveParams,
	ICustomerSearchParams,
	ICustomerSendResetPasswordParams,
	ICustomerSignUpFiturParams,
	ICustomerSignUpParams,
	ICustomerSocialNetworkGetbyCustomeParams,
	ICustomerSocialNetworkLinkParams,
	ICustomerSocialNetworkUnLinkParams,
	ICustomerSubscribeToNewsletterParams,
	ICustomerUnSubscribeToNewsletterParams,
	ICustomerValidateDataParams,
} from '../../models/serviceParams//CustomerParams';
import type {
	ICustomerGetDetailDataResponse,
	ICustomerLoginResponse,
	ICustomerSignUpResponse,
} from '../../models/serviceResponse/CustomerResponse';
import { CustomerValidationType } from '../../models/types/CustomerValidationType';
import { GenderType } from '../../models/types/GenderType';
import type { MessageType } from '../../models/types/MessageType';
import { Api } from '../../modules/shared/api';
import { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { CustomerEndPoints } from '../endPoints/CustomerEndPoints';

/**
 * @deprecated - use 'customerServiceAssignPoints' from 'packages\core\src\modules\customer\services\CustomerAssignPointsService.ts' instead.
 * Customer assign points
 *  {string} customerCode
 *  {Number} points
 */
export const customerServiceAssignPoints = (
	model: ICustomerAssignPointsParams,
): Promise<ServiceResponse<ICustomer>> => {
	return Api.post<ICustomer, ICustomerAssignPointsParams>(CustomerEndPoints.ASSIGN_POINTS, model);
};

/**
 * @deprecated - use 'customerSearchService' from 'packages\core\src\modules\customer\services\CustomerSearchService.ts' instead.
 * Search customer
 * @returns {Promise} `CustomerPropType`
 */
export const customerServiceSearch = (model: ICustomerSearchParams): Promise<ServiceResponse<ICustomer[]>> => {
	return Api.get<ICustomer[], ICustomerSearchParams>(CustomerEndPoints.SEARCH, model);
};

/**
 * @deprecated - use 'customerGetByCodeService' from 'packages\core\src\modules\customer\services\CustomerGetByCodeService.ts' instead.
 * Search customer by code
 *  {string} customerCode Customer code
 * @return {Promise} `CustomerPropType` Return customer
 */
export const customerServiceGetByCode = (model: ICustomerGetByCodeParams): Promise<ServiceResponse<ICustomer>> => {
	return Api.get<ICustomer, ICustomerGetByCodeParams>(CustomerEndPoints.GET_BY_CODE, model);
};

/**
 * @deprecated - use 'customerSaveService' from 'packages\core\src\modules\customer\services\CustomerSaveService.ts' instead.
 * Insert customer
 *  {Customer} customer Customer object
 * @returns {Promise} `CustomerPropType` Update customer
 */
export const customerServiceSave = (model: ICustomerSaveParams): Promise<ServiceResponse<ICustomer>> => {
	return Api.post<ICustomer, ICustomerSaveParams>(CustomerEndPoints.INSERT, model);
};

/**
 * @deprecated - use 'customerModifyService' from 'packages\core\src\modules\customer\services\CustomerModifyService.ts' instead.
 * Modify customer
 *  {Customer} customer Customer object
 * @returns {Promise} `CustomerPropType` Update customer
 */
export const customerServiceModify = (model: ICustomerSaveParams): Promise<ServiceResponse<ICustomer>> => {
	return Api.post<ICustomer, ICustomerSaveParams>(CustomerEndPoints.MODIFY, model);
};

/**
 * @deprecated - use 'customerSendResetPasswordService' from 'packages\core\src\modules\customer\services\CustomerSendResetPasswordService.ts' instead.
 * Sent email reset password customer
 *  {string} customerCode Customer code
 * @returns {Promise} `CustomerPropType` Update customer
 */
export const customerServiceSendResetPassword = (
	model: ICustomerSendResetPasswordParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.get<boolean, ICustomerSendResetPasswordParams>(CustomerEndPoints.SEND_RESET_PASSWORD, model);
};

/**
 * @deprecated - use 'customerCanMakeContractService' from 'packages\core\src\modules\customer\services\CustomerCanMakeContractService.ts' instead.
 * Customer can make contract
 *  {Number} customerCode Customer code
 *  {String} bookingNumber current booking number
 * @returns {Promise} `CustomerPropType` Update customer
 */
export const customerServiceCanMakeContract = (
	model: ICustomerCanMakeContractParams,
): Promise<ServiceResponse<ICustomer>> => {
	return Api.get<ICustomer, ICustomerCanMakeContractParams>(CustomerEndPoints.CAN_MAKE_CONTRACT, model);
};

/**
 * @deprecated - use 'customerAcceptGdprService' from 'packages\core\src\modules\customer\services\CustomerAcceptGdprService.ts' instead.
 * Customer accept GDPR
 *  {Number} customerCode Customer code
 */
export const customerServiceAcceptGdpr = (model: ICustomerAcceptGdprParams): Promise<ServiceResponse<ICustomer>> => {
	return Api.post<any, ICustomerAcceptGdprParams>(CustomerEndPoints.ACCEPT_GDPR, model);
};

/**
 * @deprecated - use 'customerGetPendingsService' from 'packages\core\src\modules\customer\services\CustomerGetPendingsService.ts' instead.
 * Return list pending of customer
 *  {*} customerCode Customer code
 */
export const customerServiceGetPendings = (
	model: ICustomerGetPendingsParams,
): Promise<ServiceResponse<ICustomerPending[]>> => {
	return Api.get<ICustomerPending[], ICustomerGetPendingsParams>(CustomerEndPoints.PENDINGS, model);
};

/**
 * @deprecated - use 'customerLoginService' from 'packages\core\src\modules\customer\services\CustomerLoginService.ts' instead.
 * Login customer
 * @param model ICustomerLoginParams
 * @returns Promise<ServiceResponse<ICustomerLoginResponse>>
 */
export const customerServiceLogin = (model: ICustomerLoginParams) => {
	return Api.post<ICustomerLoginResponse, ICustomerLoginParams>(CustomerEndPoints.LOGIN, model);
};

/**
 * @deprecated - use 'customerRefreshTokenService' from 'packages\core\src\modules\customer\services\CustomerRefreshTokenService.ts' instead.
 * Login refresh token
 * @param model ICustomerRefreshTokenParams
 * @returns Promise<ServiceResponse<ICustomerLoginResponse>>
 */
export const customerServiceRefreshToken = (model: ICustomerRefreshTokenParams) => {
	return Api.post<ICustomerLoginResponse, ICustomerRefreshTokenParams>(CustomerEndPoints.REFRESH_TOKEN, model);
};

/**
 * @deprecated - use 'customerValidateDataService' from 'packages\core\src\modules\customer\services\CustomerValidateDataService.ts' instead.
 */
export const customerServiceValidateData = (model: ICustomerValidateDataParams) => {
	return Api.get<boolean, ICustomerValidateDataParams>(CustomerEndPoints.VALIDATE_DATA, model);
};

/**
 * @deprecated - use 'customerLoginWithSocialNetworkService' from 'packages\core\src\modules\customer\services\CustomerLoginWithSocialNetworkService.ts' instead.
 * Customer login with social network
 * @param model ICustomerLoginWithSocialNetworkParams
 * @returns Promise<ServiceResponse<ICustomerLoginResponse>>
 */
export const customerServiceLoginWithSocialNetwork = (model: ICustomerLoginWithSocialNetworkParams) => {
	return Api.post<ICustomerLoginResponse, ICustomerLoginWithSocialNetworkParams>(
		CustomerEndPoints.SOCIAL_NETWORK_LOGIN,
		model,
	);
};

/**
 * @deprecated - use 'customerSocialNetworkGetByCustomerService' from 'packages\core\src\modules\customer\services\CustomerSocialNetworkGetByCustomerService.ts' instead.
 * Customer get social network
 * @param model ICustomerLoginWithSocialNetworkParams
 * @returns Promise<ServiceResponse<ISocialNetWorkLink[]>>
 */
export const customerServiceSocialNetworkGetByCustomer = (
	model: ICustomerSocialNetworkGetbyCustomeParams,
): Promise<ServiceResponse<ISocialNetWorkLink[]>> => {
	return Api.get<ISocialNetWorkLink[], ICustomerSocialNetworkGetbyCustomeParams>(
		CustomerEndPoints.SOCIAL_NETWORK_GET_BY_CUSTOMER,
		model,
	);
};

/**
 * @deprecated - use 'customerSocialNetworkLinkService' from 'packages\core\src\modules\customer\services\CustomerSocialNetworkLinkService.ts' instead.
 * Link customer social network
 * @param model ICustomerSocialNetworkLinkParams
 * @returns Promise<ServiceResponse<ISocialNetWorkLink>>
 */
export const customerServiceSocialNetworkLink = (
	model: ICustomerSocialNetworkLinkParams,
): Promise<ServiceResponse<ISocialNetWorkLink>> => {
	return Api.post<ISocialNetWorkLink, ICustomerSocialNetworkLinkParams>(CustomerEndPoints.SOCIAL_NETWORK_LINK, model);
};

/**
 * @deprecated - use 'customerSocialNetworkUnLinkService' from 'packages\core\src\modules\customer\services\CustomerSocialNetworkUnLinkService.ts' instead.
 * Unlink customer social network
 * @param model ICustomerSocialNetworkUnLinkParams
 * @returns Promise<ServiceResponse<boolean>>
 */
export const customerServiceSocialNetworkUnLink = (
	model: ICustomerSocialNetworkUnLinkParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, ICustomerSocialNetworkUnLinkParams>(CustomerEndPoints.SOCIAL_NETWORK_UNLINK, model);
};

/**
 * @deprecated - use 'customerSubscribeToNewsletterService' from 'packages\core\src\modules\customer\services\CustomerSubscribeToNewsletterService.ts' instead.
 * Customer subscribe to newsletter
 * @param model ICustomerSubscribeToNewsletterParams
 * @returns Promise<ServiceResponse<boolean>>
 */
export const customerServiceSubscribeToNewsletter = (
	model: ICustomerSubscribeToNewsletterParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, ICustomerSubscribeToNewsletterParams>(CustomerEndPoints.SUBSCRIBE_TO_NEWSLETTER, model);
};

/**
 * @deprecated - use 'customerUnSubscribeToNewsletterService' from 'packages\core\src\modules\customer\services\CustomerUnSubscribeToNewsletterService.ts' instead.
 * Customer unsubscribe to newsletter
 * @param model ICustomerUnSubscribeToNewsletterParams
 * @returns Promise<ServiceResponse<boolean>>
 */
export const customerServiceUnSubscribeToNewsletter = (
	model: ICustomerUnSubscribeToNewsletterParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, ICustomerUnSubscribeToNewsletterParams>(
		CustomerEndPoints.UNSUBSCRIBE_TO_NEWSLETTER,
		model,
	);
};

/**
 * @deprecated - use 'customerCanResetPasswordService' from 'packages\core\src\modules\customer\services\CustomerCanResetPasswordService.ts' instead.
 * Check if customer can reset password
 * @param model ICustomerCanResetPasswordParams
 * @returns Promise<ServiceResponse<boolean>>
 */
export const customerServiceCanResetPassword = (
	model: ICustomerCanResetPasswordParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.get<boolean, ICustomerCanResetPasswordParams>(CustomerEndPoints.CAN_RESET_PASSWORD, model);
};

/**
 * @deprecated - use 'customerResetPasswordService' from 'packages\core\src\modules\customer\services\CustomerResetPasswordService.ts' instead.
 * Change customer external password
 * @param model ICustomerResetPasswordParams
 * @returns Promise<ServiceResponse<boolean>>
 */
export const customerServiceResetPassword = (
	model: ICustomerResetPasswordParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, ICustomerResetPasswordParams>(CustomerEndPoints.RESET_PASSWORD, model);
};

/**
 * @deprecated - use 'customerSignUpService' from 'packages\core\src\modules\customer\services\CustomerSignUpService.ts' instead.
 * Register customer
 * @param model ICustomerSignUpParams
 * @returns Promise<ServiceResponse<boolean>>
 */
export const customerServiceSignUp = (
	model: ICustomerSignUpParams,
): Promise<ServiceResponse<ICustomerSignUpResponse>> => {
	return Api.post<ICustomerSignUpResponse, ICustomerSignUpParams>(CustomerEndPoints.SIGN_UP, model);
};
/**
 * @deprecated - use 'customerModifyPasswordService' from 'packages\core\src\modules\customer\services\CustomerModifyPasswordService.ts instead.
 * Modify customer's password
 * @param model ICustomerModifyPasswordParams
 */
export const customerServiceModifyPassword = (
	model: ICustomerModifyPasswordParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, ICustomerModifyPasswordParams>(CustomerEndPoints.MODIFY_PASSWORD, model);
};

/**
 * @deprecated - use 'customerDeleteService' from 'packages\core\src\modules\customer\services\CustomerDeleteService.ts' instead.
 */
export const customerServiceDelete = (model: ICustomerDeleteParams): Promise<ServiceResponse<ICustomer>> => {
	return Api.post<ICustomer, ICustomerDeleteParams>(CustomerEndPoints.DELETE, model);
};

/**
 * @deprecated - use 'customerEmailValidationService' from 'packages\core\src\modules\customer\services\CustomerEmailValidationService.ts' instead.
 * Email Validation
 * @param model ICustomerEmailValidation
 * @returns Promise<ServiceResponse<boolean>>
 */
export const customerServiceEmailValidation = (
	model: ICustomerEmailValidationParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.get<boolean, ICustomerEmailValidationParams>(CustomerEndPoints.EMAIL_VALIDATION, model);
};
export const customerServiceSignUpFitur = (model: ICustomerSignUpFiturParams) => {
	return Api.post<ICustomerLoginResponse, ICustomerSignUpFiturParams>(CustomerEndPoints.SIGNUP_FITUR, model);
};

/**
 * @deprecated - use 'customerBlockService' from 'packages\core\src\modules\customer\services\CustomerBlockService.ts' instead.
 */
export const customerServiceBlock = async (params: ICustomerBlockParams): Promise<ServiceResponse<ICustomer>> => {
	const { customer } = params;

	const response = await customerServiceModify({
		...customer,
		blocked: !customer.blocked,
		gender: customer.gender || GenderType.Empty,
	});

	return response;
};

/**
 * @deprecated - use 'customerServiceSearch' from 'packages\core\src\modules\customer\services\CustomerSearchService.ts' instead.
 */
export const customerServiceSearchByField = async (
	params: ICustomerSearchParams,
): Promise<ServiceResponse<ICustomer[]>> => {
	const tasks = [];
	if (params.taxIdentificationNumber) {
		tasks.push(
			customerServiceSearch({
				closedBookings: params.closedBookings,
				taxIdentificationNumber: params.taxIdentificationNumber,
			}),
		);
	}

	if (params.driverLicense) {
		tasks.push(
			customerServiceSearch({ closedBookings: params.closedBookings, driverLicense: params.driverLicense }),
		);
	}

	if (params.email) {
		tasks.push(customerServiceSearch({ closedBookings: params.closedBookings, email: params.email }));
	}

	const requestResponses = await Promise.all(tasks);

	const responseData: ICustomer[] = [];
	const responseErrors: MessageType[] = [];

	const updateResponse = (customers: ICustomer[]): void => {
		customers.forEach((customer) => {
			if (!responseData.some((item) => item.code === customer.code)) {
				responseData.push(customer);
			}
		});
	};

	requestResponses.forEach((response) => {
		if (response.ok && response.data) {
			updateResponse(response.data);
		} else {
			responseErrors.push(...response.errors);
		}
	});

	if (responseErrors.length > 0) {
		return new ServiceResponse<ICustomer[]>(false, null, responseErrors);
	}

	return new ServiceResponse<ICustomer[]>(true, responseData, []);
};

/**
 * @deprecated - use 'customerModifyDuplicateTaxIdService' from 'packages\core\src\modules\customer\services\CustomerModifyDuplicateTaxIdService.ts' instead.
 */
export const customerServiceModifyDuplicateTaxId = async (
	params: ICustomerModifyDuplicateTaxIdParams,
): Promise<ServiceResponse<ICustomer>> => {
	const { bookingCustomer, selectedCustomer } = params;

	const bookingCustomerResponse = await customerServiceModify({
		...bookingCustomer,
		gender: bookingCustomer.gender || 0,
		driverLicenseExpeditionDate: undefined,
		driverLicenseExpirationDate: undefined,
		driverLicenseNumber: undefined,
		email: undefined,
		taxIdentificationNumber: undefined,
		validationType: CustomerValidationType.BASIC,
	});
	const selectedCustomerResponse = await customerServiceModify({
		...selectedCustomer,
		email: bookingCustomer.email,
		gender: selectedCustomer.gender || 0,
		validationType: CustomerValidationType.BASIC,
	});

	// CHECK ERRORS
	if (!bookingCustomerResponse.ok) {
		return new ServiceResponse<ICustomer>(false, null, bookingCustomerResponse.errors);
	}

	if (!selectedCustomerResponse.ok) {
		return new ServiceResponse<ICustomer>(false, null, selectedCustomerResponse.errors);
	}

	if (bookingCustomerResponse.data && selectedCustomerResponse.data) {
		// ADD COMMENT
		const commentsTasks = [];
		commentsTasks.push(
			commentServiceAddToCustomer({
				code: bookingCustomer.code as string,
				message: `'DATA_REPLACE_EMAIL/${JSON.stringify({
					after: `{email: empty}`,
					before: `{email: ${bookingCustomer.email}}`,
				})}'`,
			}),
		);
		commentsTasks.push(
			commentServiceAddToCustomer({
				code: selectedCustomer.code as string,
				message: `'DATA_REPLACE_EMAIL/${JSON.stringify({
					after: `{email: ${bookingCustomer.email}}`,
					before: `{email: ${selectedCustomer.email}}`,
				})}'`,
			}),
		);

		await Promise.all(commentsTasks);
	}

	return new ServiceResponse<ICustomer>(true, selectedCustomerResponse.data, []);
};

/**
 * @deprecated - use 'customerServiceGetDetailData' from 'packages\core\src\modules\customer\services\CustomerGetDetailDataService.ts' instead.
 */
export const customerServiceGetDetailData = async (
	params: ICustomerGetDetailData,
): Promise<ServiceResponse<ICustomerGetDetailDataResponse>> => {
	const { customerCode, countries } = params;
	let provinces: IProvince[] = [];
	let birthProvinces: IProvince[] = [];
	let cities: ICity[] = [];
	let birthCities: ICity[] = [];

	// GET CUSTOMER BY CODE
	const customerResponse = await customerServiceGetByCode({ customerCode });

	if (!customerResponse.ok || !customerResponse.data) {
		return new ServiceResponse(false, null, customerResponse.errors);
	}

	const birthCountry = countries.find((country) => country.code === customerResponse.data?.birthCountry);

	// GET BIRTH CUSTOMER DATA
	if (birthCountry && birthCountry.data) {
		// BIRTH PROVINCES
		const birthProvincesResponse = await provinceServiceGetByCountry({
			countryCode: birthCountry.code,
		});

		if (birthProvincesResponse.ok && birthProvincesResponse.data) {
			birthProvinces = birthProvincesResponse.data;
		}

		// GET BIRTH CITIES
		if (customerResponse.data.birthProvince) {
			const birthCitiesResponse = await cityServiceGetByProvince({
				provinceCode: customerResponse.data.birthProvince,
			});

			if (birthCitiesResponse.ok && birthCitiesResponse.data) {
				birthCities = birthCitiesResponse.data;
			}
		}
	}

	// CUSTOMER RESIDENT DATA
	const country = countries.find((country) => country.code === customerResponse.data?.country);

	if (country && country.data && customerResponse.data.postalCode) {
		// GET PROVINCES
		const provincesResponse = await provinceServiceGetByCountryAndPostCode({
			countryCode: country.code,
			postCode: customerResponse.data.postalCode,
		});

		if (provincesResponse.ok && provincesResponse.data) {
			provinces = provincesResponse.data;
		}

		// GET CITIES
		const citiesResponse = await cityServiceGetByCountryAndPostCode({
			countryCode: country.code,
			postCode: customerResponse.data.postalCode,
		});

		if (citiesResponse.ok && citiesResponse.data) {
			cities = citiesResponse.data;
		}
	}

	return new ServiceResponse(true, {
		birthProvinces,
		birthCities,
		provinces,
		cities,
		customer: customerResponse.data,
	});
};

'use client';

import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

import { DropdownMenuCheckboxItem } from './components/DropdownMenuCheckboxItem';
import { DropdownMenuContent } from './components/DropdownMenuContent';
import { DropdownMenuItem } from './components/DropdownMenuItem';
import { DropdownMenuLabel } from './components/DropdownMenuLabel';
import { DropdownMenuRadioItem } from './components/DropdownMenuRadioItem';
import { DropdownMenuSeparator } from './components/DropdownMenuSeparator';
import { DropdownMenuShortcut } from './components/DropdownMenuShortcut';
import { DropdownMenuSubContent } from './components/DropdownMenuSubContent';
import { DropdownMenuSubTrigger } from './components/DropdownMenuSubTrigger';

const DropdownMenu = DropdownMenuPrimitive.Root;

const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;

const DropdownMenuGroup = DropdownMenuPrimitive.Group;

const DropdownMenuPortal = DropdownMenuPrimitive.Portal;

const DropdownMenuSub = DropdownMenuPrimitive.Sub;

const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;

export {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuCheckboxItem,
	DropdownMenuRadioItem,
	DropdownMenuLabel,
	DropdownMenuShortcut,
	DropdownMenuGroup,
	DropdownMenuPortal,
	DropdownMenuSub,
	DropdownMenuSubContent,
	DropdownMenuSubTrigger,
	DropdownMenuRadioGroup,
	DropdownMenuSeparator,
};

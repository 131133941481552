import * as React from 'react';

import { FaBars } from 'react-icons/fa6';

import { maxedLogo, miniLogo } from './constants';
import { Button } from '../button/Button';
import { cn } from '../lib/utils/tailwindClass';
import { Sheet, SheetContent, SheetDescription, SheetTitle, SheetTrigger } from '../sheet/Sheet';
import { Sidebar } from '../sidebar/Sidebar';
import type { INavItem } from '../sidebar/types';

import '../lib/styles/scrollbar.css';

export interface IHeaderProps {
	className?: string;
	sidebarOpen?: boolean;
	mobileSidebarOpen?: boolean;
	setMobileSidebarOpen?: (open: boolean) => void;
	children?: React.ReactNode | React.ReactNode[];
	menuItems?: INavItem[];
	AnchorComponent?: React.ElementType;
	renderSidebarHeader?: () => React.ReactNode;
}

const Header = React.forwardRef<HTMLElement, IHeaderProps>(
	(
		{
			className,
			sidebarOpen,
			mobileSidebarOpen,
			setMobileSidebarOpen,
			menuItems,
			AnchorComponent,
			renderSidebarHeader,
			children,
		},
		ref,
	) => {
		const Comp = AnchorComponent ? AnchorComponent : 'a';
		return (
			<header className={cn('tailwind-header', className)} ref={ref}>
				<div className="header-left-content">
					<div className="tw-block tw-h-full">
						{sidebarOpen ? (
							<Comp className="header-maxed-logo" href="/" to="/">
								<img alt="Centauro Rent a Car" height="50" src={maxedLogo} width="160" />
							</Comp>
						) : (
							<Comp className="header-mini-logo" href="/" to="/">
								<img alt="Centauro Rent a Car" height="30" src={miniLogo} width="30" />
							</Comp>
						)}
					</div>

					{/* Sidebar menu */}
					<Sheet onOpenChange={setMobileSidebarOpen} open={mobileSidebarOpen}>
						<SheetTrigger asChild>
							<Button className="header-sidebar-trigger" color="light">
								<FaBars className="tw-w-4 tw-h-4" />
							</Button>
						</SheetTrigger>
						<SheetContent className="header-sheet-content" side="left">
							<div className="tw-hidden">
								<SheetTitle>Application sidebar</SheetTitle>
								<SheetDescription>Sidebar with diferent routes</SheetDescription>
							</div>
							<Sidebar
								AnchorComponent={AnchorComponent}
								menuItems={menuItems || []}
								renderSidebarHeader={renderSidebarHeader}
							/>
						</SheetContent>
					</Sheet>
				</div>

				<div className="header-right-content">{children}</div>
			</header>
		);
	},
);

Header.displayName = 'Header';

export { Header };

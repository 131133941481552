import {
	vehicleGroupServiceDelete,
	vehicleGroupServiceGetByBranch,
	vehicleGroupServiceGetByProvider,
	vehicleGroupServiceInsert,
	vehicleGroupServiceModify,
	vehicleGroupServiceSearch,
} from '../../data/services/VehicleGroupService';
import type { IGroup, IVehicleGroup, IVehicleGroupByProvider } from '../../models/entities/VehicleGroup';
import type {
	IVehicleGroupDeleteParams,
	IVehicleGroupGetByBranchParams,
	IVehicleGroupInsertParams,
	IVehicleGroupSearchParams,
} from '../../models/serviceParams/VehicleGroupParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';

/**
 * @deprecated use 'vehicleGroupGetByBranch' from 'packages\core\src\modules\fleet\vehicleGroup\state\actions\VehicleGroupActions.ts' instead.
 * Retrieves vehicle groups associated with a specific branch.
 *
 * @param {IGroup[]} payload - The vehicle groups to be retrieved.
 * @param {IVehicleGroupGetByBranchParams} params - Parameters for identifying vehicle groups related to a specific branch.
 * @returns {Promise<void>} - A promise that resolves to an array of vehicle groups.
 */
export const vehicleGroupGetByBranch = createAsyncAction<IGroup[], IVehicleGroupGetByBranchParams>(
	'vehicleGroup/getByBranch',
	vehicleGroupServiceGetByBranch,
);

/**
 * @deprecated - use 'vehicleGroupGetByProvider' from 'packages\core\src\modules\fleet\vehicleGroup\state\actions\VehicleGroupActions.ts' instead.
 * Retrieves vehicle groups associated with a specific provider.
 *
 * @param {IVehicleGroup} payload - The vehicle groups to be retrieved.
 * @param {IServiceBaseParams} params - Base service parameters, potentially used for filtering or configuration.
 * @returns {Promise<void>} - A promise that resolves to an array or a specific vehicle group depending on the provider.
 */
export const vehicleGroupGetByProvider = createAsyncAction<IVehicleGroupByProvider[], IServiceBaseParams>(
	'vehicleGroup/getByProvider',
	vehicleGroupServiceGetByProvider,
);

/**
 * @deprecated - use 'vehicleGroupSearch' from 'packages\core\src\modules\fleet\vehicleGroup\state\actions\VehicleGroupActions.ts' instead.
 * Searches for vehicle groups.
 *
 * @param {IVehicleGroup[]} payload - The vehicle groups to be searched.
 * @param {IVehicleGroupSearchParams} params - Parameters for identifying vehicle groups.
 * @returns {Promise<void>} - A promise that resolves to an array of vehicle groups.
 */
export const vehicleGroupSearch = createAsyncAction<IVehicleGroup[], IVehicleGroupSearchParams>(
	'fleet/vehicleGroup/search',
	vehicleGroupServiceSearch,
);

/**
 * @deprecated - use 'vehicleGroupInsert' from 'packages\core\src\modules\fleet\vehicleGroup\state\actions\VehicleGroupActions.ts' instead.
 * Inserts a new vehicle group.
 *
 * @param {IVehicleGroup} payload - The vehicle group to be inserted.
 * @param {IVehicleGroupInsertParams} params - Parameters for inserting a new vehicle group.
 * @returns {Promise<void>} - A promise that resolves to the inserted vehicle group.
 */
export const vehicleGroupInsert = createAsyncAction<IVehicleGroup, IVehicleGroupInsertParams>(
	'fleet/vehicleGroup/insert',
	vehicleGroupServiceInsert,
);

/**
 * @deprecated - use 'vehicleGroupModify' from 'packages\core\src\modules\fleet\vehicleGroup\state\actions\VehicleGroupActions.ts' instead.
 * Modifies an existing vehicle group.
 *
 * @param {IVehicleGroup} payload - The vehicle group to be modified.
 * @param {IVehicleGroupInsertParams} params - Parameters for modifying an existing vehicle group.
 * @returns {Promise<void>} - A promise that resolves to the modified vehicle group.
 */
export const vehicleGroupModify = createAsyncAction<IVehicleGroup, IVehicleGroupInsertParams>(
	'fleet/vehicleGroup/modify',
	vehicleGroupServiceModify,
);

/**
 * @deprecated - use 'vehicleGroupDelete' from 'packages\core\src\modules\fleet\vehicleGroup\state\actions\VehicleGroupActions.ts' instead.
 * Deletes a vehicle group.
 *
 * @param {IVehicleGroup} payload - The vehicle group to be deleted.
 * @param {IVehicleGroupDeleteParams} params - Parameters for deleting a vehicle group.
 * @returns {Promise<void>} - A promise that resolves to the deleted vehicle group.
 */
export const vehicleGroupDelete = createAsyncAction<IVehicleGroup, IVehicleGroupDeleteParams>(
	'fleet/vehicleGroup/delete',
	vehicleGroupServiceDelete,
);

/**
 * @deprecated - use 'vehicleGroupClear' from 'packages\core\src\modules\fleet\vehicleGroup\state\actions\VehicleGroupActions.ts' instead.
 * Clears the vehicle group.
 */
export const vehicleGroupClear = createSyncAction('fleet/vehicleGroup/clear');

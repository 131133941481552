import { t } from '@lingui/macro';
import type { Location } from 'react-router-dom';

import type { IPartner } from '@crac/core/models/entities/Partner';
import type { IProvider } from '@crac/core/models/entities/Provider';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaChartBar, FaCreditCard, FaEuroSign, FaMagnifyingGlass, FaRegFile } from '@crac/ui/icons';
import type { INavItem } from '@crac/ui/sidebar';

export const navigationItems = (
	partner: IPartner,
	providers: IProvider[],
	permissions: string[],
	location: Location<any>,
): INavItem[] => {
	const [provider] = providers;

	const navItems: INavItem[] = [
		{
			icon: FaMagnifyingGlass,
			name: t`Booking search`,
			roles: [PermissionsType.BookingSearch],
			url: '/bookings/search',
		},
		{
			icon: FaRegFile,
			name: t`Booking new`,
			roles: [PermissionsType.BookingNew],
			url: '/bookings/new',
		},
		{
			icon: FaEuroSign,
			name: t`Commissions`,
			roles: [PermissionsType.Commissions],
			url: '/commissions',
		},
		{
			icon: FaChartBar,
			name: t`Commissions Chart`,
			roles: [PermissionsType.Commissions],
			url: '/dashboard',
		},
	];

	// Conditionally add the "Cards" navigation item
	if (provider && provider.partnerPrepaidPaymentTerm === partner.paymentTermsCode) {
		navItems.push({
			icon: FaCreditCard,
			name: t`Cards`,
			roles: [PermissionsType.BookingNew],
			url: '/cards',
		});
	}

	// Filter navItems based on permissions and mark the active item based on location.pathname
	return navItems
		.filter((item) => item.roles.some((role) => permissions.includes(role)))
		.map((item) => ({
			...item,
			active: location.pathname === item.url,
		}));
};

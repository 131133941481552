import type { IAvailabilityAndPrice } from '@crac/core/modules/booking/availability/entities/AvailabilityAndPrice';
import type { IAvailabilityAndPriceByBranch } from '@crac/core/modules/booking/availability/entities/AvailabilityAndPriceByBranch';
import type { IBookingGetAvailabilityParams } from '@crac/core/modules/booking/availability/services/BookingGetAvailabilityService';

import { branchServicesGetNearbyBranchesWithAvailability } from './BranchService';
import { couponServiceCheck } from './CouponService';
import { customerServiceAcceptGdpr } from './CustomerService';
import { discountServiceCheck } from './DiscountServicse';
import { emailServiceSendFeedBack } from './EmailService';
import { extraDriverServiceGetByBooking } from './ExtraDriverService';
import { providerServiceGetByBranch } from './ProviderService';
import { servicesServiceGetAll } from './ServicesService';
import { getMappedAndFilterVehicleGroupsAvailability } from '../../business/booking/BookingAvailability';
import { checkCanAddExtraDriver } from '../../business/booking/BookingExtraDrivers';
import { recalculateDiscounts } from '../../business/booking/BookingNewManageState';
import { canCheckNearbyBranchesAvailability } from '../../business/branch/BranchWithAvailability';
import type { IBooking } from '../../models/entities/Booking';
import type { IService } from '../../models/entities/Service';
import type {
	IBookingAllowPendingParams,
	IBookingBillBookingParams,
	IBookingGetByAccidentDataParams,
	IBookingGetByBookingNumberParams,
	IBookingGetByCustomerParams,
	IBookingGetByVehicleParams,
	IBookingGetPendingParams,
	IBookingHasUpgradeParams,
	IBookingInsertExternalParams,
	IBookingInvoiceableChangeParams,
	IBookingRefundPaymentsParams,
	IBookingRegisterContractParams,
	IBookingRevertToConfirmedParams,
	IBookingSearchParams,
	IBookingSendExtendPaymentEmailParams,
	IBookingSetDropOffDataParams,
	IBookingSignAndRegisterContractParams,
	IBookingSignContractParams,
} from '../../models/serviceParams/BookingParams';
import type { IPaymentCallbackParams } from '../../models/serviceParams/PaymentParams';
import type { IBookingInsertExternalResponse } from '../../models/serviceResponse/BookingResponse';
import { VehicleCategory } from '../../models/types/VehicleCategory';
import type { IBookingLine } from '../../modules/booking/bookingLine/entities/BookingLine';
import { bookingLineGetByBookingService } from '../../modules/booking/bookingLine/services/BookingLineGetByBookingService';
import { Api } from '../../modules/shared/api';
import { CreationMethod } from '../../modules/shared/types/CreationMethod';
import { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import type { IBookingNewSetPickUpBranchPayloadType } from '../../redux/types/ActionsPayload';
import type {
	IBookingCheckInParams,
	IBookingCheckInPayload,
} from '../../redux-store/actionsParams/BookingCheckInParams';
import type {
	IBookingNewCouponData,
	IBookingNewDiscountData,
	IBookingNewSetBranchParams,
	IBookingNewSetDiscountCouponParams,
	IBookingNewSetDiscountCouponPayload,
} from '../../redux-store/actionsParams/BookingNewActionsParams';
import { BookingEndPoints } from '../endPoints/BookingEndPoints';

/**
 * @deprecated Use `bookingGetAvailabilityService` from `modules/booking/availability/services`
 * Get availability and check nearby branches
 * @params `IBookingGetAvailabilityParams`
 * @returns {Promise<IAvailabilityAndPrice>} `IAvailabilityAndPrice`
 */
export const bookingServiceGetAvailability = async (
	serviceParams: IBookingGetAvailabilityParams,
): Promise<ServiceResponse<IAvailabilityAndPrice | null>> => {
	let nearbyBranches: string[] = [];
	const { pickUpBranch, vehicleCategory, ...params } = serviceParams;

	const availabilityResponse = await Api.get<IAvailabilityAndPrice, IBookingGetAvailabilityParams>(
		BookingEndPoints.GET_AVAILABILITY,
		params,
	);
	// RETURN ERROR
	if (!availabilityResponse.ok || !availabilityResponse.data) {
		return availabilityResponse;
	}

	// CHECK NEARBY BRANCHES
	const checkNearbyBranches = canCheckNearbyBranchesAvailability({
		vehicleGroupsAvailability: availabilityResponse.data.vehicleGroupsAvailability,
		pickUpBranch,
	});

	// Request nearby branches
	if (checkNearbyBranches) {
		const nearbyBranchesResponse = await branchServicesGetNearbyBranchesWithAvailability(params);

		nearbyBranches = nearbyBranchesResponse.data || [];
	}

	// FILTER AND MAP VEHICLE GROUPS
	let vehicleGroupsAvailability = getMappedAndFilterVehicleGroupsAvailability(
		availabilityResponse.data.vehicleGroupsAvailability,
		params.creationMethod,
		params.disableFilter,
	);

	/*
	 * FILTER VEHICLE CATEGORY.
	 * Hasta que se añada vehicleCategory en la disponibilidad, solo filtramos connectCar
	 */
	if (vehicleCategory === VehicleCategory.ConnectCar) {
		vehicleGroupsAvailability = vehicleGroupsAvailability.map((group) => ({
			...group,
			choosable: group.connectCar,
		}));
	}

	return new ServiceResponse<IAvailabilityAndPrice>(
		true,
		{
			...availabilityResponse.data,
			vehicleGroupsAvailability,
			nearbyBranches,
		},
		[],
	);
};

/**
 * @deprecated - use `bookingInsertExternalService` from `packages\core\src\modules\booking\services\BookingInsertExternalService.ts`
 * Return BookingInsert width payment
 */
export const bookingServiceInsertExternal = async (
	model: IBookingInsertExternalParams,
): Promise<ServiceResponse<IBookingInsertExternalResponse>> => {
	const response = await Api.post<IBookingInsertExternalResponse, IBookingInsertExternalParams>(
		BookingEndPoints.INSERT,
		model,
	);

	if (response.ok && response.data?.transaction && !response.data.transaction.transactionId) {
		await emailServiceSendFeedBack({
			json: JSON.stringify({
				from: model.from ?? 'web',
				action: BookingEndPoints.INSERT,
				params: model,
				response: response.data,
			}),
			creationMethod: CreationMethod.BRANCH,
			files: [],
		});
	}

	return response;
};

export const bookingServiceInsertCallBack = async (
	model: IPaymentCallbackParams,
): Promise<ServiceResponse<IBookingInsertExternalResponse>> => {
	const response = await Api.post<IBookingInsertExternalResponse, IPaymentCallbackParams>(
		BookingEndPoints.INSERT_CALLBACK,
		model,
	);

	if (response.ok && response.data?.transaction && !response.data.transaction.transactionId) {
		await emailServiceSendFeedBack({
			json: JSON.stringify({
				from: model.from ?? 'web',
				action: BookingEndPoints.INSERT_CALLBACK,
				params: model,
				response: response.data,
			}),
			creationMethod: CreationMethod.BRANCH,
			files: [],
		});
	}

	return response;
};

/**
 * @deprecated - use `bookingGetByBookingNumberService` from `packages\core\src\modules\booking\services\BookingGetByBookingNumberService.ts` instead
 * Get booking by number
 *  {string} bookingNumber Booking number
 * @return {Promise<BookingPropType>} `BookingPropType`
 */
export const bookingServiceGetByBookingNumber = (
	model: IBookingGetByBookingNumberParams,
): Promise<ServiceResponse<IBooking>> => {
	return Api.get<IBooking, IBookingGetByBookingNumberParams>(BookingEndPoints.GET_BY_NUMBER, model);
};

/**
 * @deprecated Use `bookingRegisterContractService` from `modules/booking/pickUp/services`
 * Register contract by booking number
 *  {string} bookingNumber booking number
 * @returns {Promise<BookingPropType>} `BookingPropType`
 */
export const bookingServiceRegisterContract = (
	model: IBookingRegisterContractParams,
): Promise<ServiceResponse<IBooking>> => {
	return Api.post<IBooking, IBookingRegisterContractParams>(BookingEndPoints.REGISTER_CONTRACT, model);
};

/**
 * @deprecated Use `bookingSignContractService` from `modules/booking/pickUp/services`
 * Sign contract
 *  {string} bookingNumber booking number
 *  {string} computerName ComputerName
 *  {string} ip Pinpad ip
 */
export const bookingServiceSignContract = (model: IBookingSignContractParams): Promise<ServiceResponse<IBooking>> => {
	return Api.post<IBooking, IBookingSignContractParams>(BookingEndPoints.SIGN, model);
};

/**
 * @deprecated Use `bookingSearchService` from `modules/booking/services/BookingSearchService.ts`
 * Search booking
 *  {string} string voucherNumber
 *  {string} string bookingNumber
 *  {string} string contractNumber
 *  {string} string plateNumber
 * @returns {Array<BookingPropType>} BookingPropType
 */
export const bookingServiceSearch = (model: IBookingSearchParams): Promise<ServiceResponse<IBooking[]>> => {
	return Api.get<IBooking[], IBookingSearchParams>(BookingEndPoints.SEARCH, model);
};

/**
 * @deprecated - use `bookingGetByVehicleService` from `packages\core\src\modules\booking\services\BookingGetByVehicleService.ts`
 * Get bookings by vehicle
 *  {string} string plateNumber
 * @returns {Array<BookingPropType>} `Array<BookingPropType>`
 */
export const bookingServiceGetByVehicle = (model: IBookingGetByVehicleParams): Promise<ServiceResponse<IBooking[]>> => {
	return Api.get<IBooking[], IBookingGetByVehicleParams>(BookingEndPoints.GET_BY_VEHICLE, model);
};

/**
 * @deprecated Use `bookingGetByCustomerService` from `packages\core\src\modules\booking\services\BookingGetByCustomerService.ts`
 * Get bookings by customer
 *  {string} string customerCode
 * @returns {Array<BookingPropType>} `Array<BookingPropType>`
 */
export const bookingServiceGetByCustomer = (
	model: IBookingGetByCustomerParams,
): Promise<ServiceResponse<IBooking[]>> => {
	return Api.get<IBooking[], IBookingGetByCustomerParams>(BookingEndPoints.GET_BY_CUSTOMER, model);
};

/**
 * @deprecated Use `bookingGetPendingsService` from `packages\core\src\modules\booking\services\BookingGetPendingsService.ts`
 * Return list booking pending by branch
 *  {*} branchCode Branch code
 */
export const bookingServiceGetPendings = (model: IBookingGetPendingParams): Promise<ServiceResponse<IBooking[]>> => {
	return Api.get<IBooking[], IBookingGetPendingParams>(BookingEndPoints.PENDINGS, model);
};

/**
 * @deprecated Use `bookingBillBookingService` from `packages\core\src\modules\booking\services\BookingBillBookingService.ts`
 * Bill booking
 *  {string} string bookingNumber
 * @returns {Array<BookingPropType>} `Array<BookingPropType>`
 */
export const bookingServiceBillBooking = (model: IBookingBillBookingParams): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IBookingBillBookingParams>(BookingEndPoints.BILL_BOOKING, model);
};

/**
 * Refund payments
 *  {Object} BookingPropType booking
 * @returns {Array<BookingPropType>} `Array<BookingPropType>`
 */
export const bookingServiceRefundPayments = (
	model: IBookingRefundPaymentsParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IBookingRefundPaymentsParams>(BookingEndPoints.REFUND_PAYMENTS, model);
};

/**
 * @deprecated - use `bookingHasUpgradeService` from `packages\core\src\modules\booking\services\BookingHasUpgradeService.ts`
 */
export const bookingServiceHasUpgrade = (model: IBookingHasUpgradeParams): Promise<ServiceResponse<boolean>> => {
	return Api.get<boolean, IBookingHasUpgradeParams>(BookingEndPoints.BOOKING_HAS_UPGRADE, model);
};

/**
 * @deprecated Use `bookingSendExtendPaymentEmailService` from `packages\core\src\modules\booking\services\BookingSendExtendPaymentEmailService.ts`
 */
export const bookingServiceSendExtendPaymentEmail = (
	model: IBookingSendExtendPaymentEmailParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IBookingSendExtendPaymentEmailParams>(BookingEndPoints.SEND_EXTEND_PAYMENT_EMAIL, model);
};

export const bookingServiceRevertToConfirmed = (
	model: IBookingRevertToConfirmedParams,
): Promise<ServiceResponse<IBooking>> =>
	Api.post<IBooking, IBookingRevertToConfirmedParams>(BookingEndPoints.REVERT_TO_CONFIRMED, model);
/**
 * @deprecated - use 'bookingServiceAllowPending' from 'packages\core\src\modules\booking\services\BookingAllowPendingService.ts' instead.
 */
export const bookingServiceAllowPending = (model: IBookingAllowPendingParams): Promise<ServiceResponse<IBooking>> =>
	Api.post<IBooking, IBookingAllowPendingParams>(BookingEndPoints.ALLOW_PENDING, model);

/**
 * @deprecated - use 'bookingServiceInvoiceableChange' from 'packages\core\src\modules\booking\services\BookingInvoiceableChangeService.ts' instead.
 */
export const bookingServiceInvoiceableChange = (
	model: IBookingInvoiceableChangeParams,
): Promise<ServiceResponse<IBooking>> =>
	Api.post<IBooking, IBookingInvoiceableChangeParams>(BookingEndPoints.INVOICEABLE_CHANGE, model);

/**
 * @deprecated - use 'bookingSetDropOffDataService' from 'packages\core\src\modules\booking\services\BookingSetDropOffData.ts' instead.
 */
export const bookingServiceSetDropOffData = (
	model: IBookingSetDropOffDataParams,
): Promise<ServiceResponse<IBooking>> => {
	return Api.post<IBooking, IBookingSetDropOffDataParams>(BookingEndPoints.SET_DROP_OFF_DATA, model);
};

const getExtraDriverQuantity = (services: IService[], bookingLines: IBookingLine[]) => {
	const edServicesCode: string[] = services.filter((service) => service.ed).map((service) => service.code);
	return bookingLines.filter((line) => edServicesCode.includes(line.code)).length;
};

const loadAllData = async (params: IBookingCheckInParams) => {
	const { bookingNumber, token, creationMethod, locale } = params;

	const bookingRequest = bookingServiceGetByBookingNumber({
		bookingNumber,
		creationMethod,
		locale,
		token,
	});
	const servicesRequest = servicesServiceGetAll({
		creationMethod,
		locale,
		token,
	});
	const bookingLinesRequest = bookingLineGetByBookingService({
		bookingNumber,
		creationMethod,
		locale,
		token,
	});

	const extraDriversRequest = extraDriverServiceGetByBooking({
		bookingNumber,
		creationMethod,
		locale,
		token,
	});

	const [bookingResponse, servicesResponse, bookingLinesResponse, extraDriversResponse] = await Promise.all([
		bookingRequest,
		servicesRequest,
		bookingLinesRequest,
		extraDriversRequest,
	]);

	return {
		bookingLinesResponse,
		bookingResponse,
		extraDriversResponse,
		servicesResponse,
	};
};

export const bookingServiceCheckIn = async (
	params: IBookingCheckInParams,
): Promise<ServiceResponse<IBookingCheckInPayload>> => {
	let showAdditionalDriver = false;
	let extraDriverQuantity = 0;

	const { bookingLinesResponse, bookingResponse, servicesResponse, extraDriversResponse } = await loadAllData(params);
	if (bookingResponse.ok && bookingResponse.data) {
		if (servicesResponse.ok && servicesResponse.data) {
			if (bookingLinesResponse.ok && bookingLinesResponse.data) {
				showAdditionalDriver = checkCanAddExtraDriver(
					servicesResponse.data,
					bookingLinesResponse.data,
					extraDriversResponse.data,
				);
				extraDriverQuantity = getExtraDriverQuantity(servicesResponse.data, bookingLinesResponse.data);
			} else {
				showAdditionalDriver = false;
			}
		} else {
			showAdditionalDriver = false;
		}
		const responseData: IBookingCheckInPayload = {
			booking: bookingResponse.data,
			extraDriverQuantity,
			showDigitization: !bookingResponse.data.hasValidDigitization,
			showExtraDriver: showAdditionalDriver,
			showPayment: Boolean(bookingResponse.data.pendingAmount),
		};
		return new ServiceResponse<IBookingCheckInPayload>(true, responseData, []);
	}
	return new ServiceResponse<IBookingCheckInPayload>(false, null, bookingResponse.errors);
};

/**
 * Sets the pick-up branch for a new booking service.
 *
 * @param params - The parameters for setting the pick-up branch.
 * @returns A promise that resolves to a ServiceResponse containing the updated parameters.
 */
export const bookingNewServiceSetPickUpBranch = async (params: IBookingNewSetBranchParams) => {
	const { currentProvider, branch, deliveryType } = params;
	let provider = currentProvider;

	if ((branch && !provider) || (branch && provider && branch.provider !== provider.name)) {
		const response = await providerServiceGetByBranch({
			branchCode: branch.code,
			creationMethod: CreationMethod.WEB,
		});

		if (response.ok && response.data) {
			provider = response.data;
		}
	}

	return new ServiceResponse<IBookingNewSetPickUpBranchPayloadType>(true, {
		deliveryType,
		branch,
		provider,
	});
};

export const bookingNewServiceSetDiscountCoupon = async (
	params: IBookingNewSetDiscountCouponParams,
): Promise<ServiceResponse<IBookingNewSetDiscountCouponPayload>> => {
	const couponPromise = couponServiceCheck({
		agencyCode: params.agencyCode,
		branchCode: params.pickUpBranchCode,
		coupon: params.coupon,
		creationMethod: params.creationMethod,
		customerCode: params.customerCode,
		quoteDateTime: new Date(),
		token: params.token,
		vendorCode: params.vendorCode,
	});

	const discountPromise = discountServiceCheck({
		agencyCode: params.agencyCode,
		coupon: params.coupon,
		creationMethod: params.creationMethod,
		customerCode: params.customerCode,
		dropOffDateTime: params.dropOffDateTime,
		pickUpBranchCode: params.pickUpBranchCode,
		pickUpDateTime: params.pickUpDateTime,
		quoteDateTime: new Date().toJSON(),
		token: params.token,
		vehicleGroupCodePaid: params.vehicleGroupCode,
		vendorCode: params.vendorCode,
	});

	await Promise.all([couponPromise, discountPromise]);

	const couponResponseData = await couponPromise;
	const discountResponseData = await discountPromise;

	const couponData: IBookingNewCouponData | undefined =
		couponResponseData.ok && couponResponseData.data
			? { amount: couponResponseData.data?.amount, code: params.coupon }
			: params.couponData;
	const discountData: IBookingNewDiscountData | undefined =
		discountResponseData.ok && discountResponseData.data
			? {
					code: params.coupon,
					percentage: discountResponseData.data?.percentage,
					amountToPointsRelation: discountResponseData.data?.amountToPointsRelation,
					image: params.discountData?.image,
				}
			: params.discountData;

	if ((discountResponseData.ok && discountResponseData.data) || (couponResponseData.ok && couponResponseData.data)) {
		// RETURN SUCCESS DATA
		const { bookingLines, paymentLines } = recalculateDiscounts({
			agencyCode: params.agencyCode,
			bookingLines: params.bookingLines,
			couponData,
			creationMethod: CreationMethod.WEB,
			customer: params.customer,
			discountData,
			pointsData: params.pointsData,
			provider: params.provider,
		});

		return new ServiceResponse(true, {
			bookingLines,
			couponData,
			discountData,
			paymentLines,
		});
	}

	// CHECK ERRORS
	if (!couponResponseData.ok && couponResponseData.errors.length > 0) {
		return new ServiceResponse<IBookingNewSetDiscountCouponPayload>(false, null, couponResponseData.errors);
	}

	if (!discountResponseData.ok && discountResponseData.errors.length > 0) {
		return new ServiceResponse<IBookingNewSetDiscountCouponPayload>(false, null, discountResponseData.errors);
	}

	return new ServiceResponse<IBookingNewSetDiscountCouponPayload>(false, null, [
		{
			code: '',
			field: '',
			message: 'An error occurred while validating the discount',
		},
	]);
};

/**
 * @deprecated Use `bookingGetAvailabilityByNearbyBranchService` from `modules/booking/availability/services`
 */
export const bookingServiceGetAvailabilityByNearbyBranch = async ({
	pickUpBranch,
	...params
}: IBookingGetAvailabilityParams): Promise<ServiceResponse<IAvailabilityAndPriceByBranch[]>> => {
	if (pickUpBranch?.nearbyBranches.length === 0) {
		return {
			ok: true,
			data: [],
			errors: [],
		};
	}

	const nearbyBranches = pickUpBranch?.nearbyBranches || [];
	const tasks: any[] = [];

	nearbyBranches.forEach((pickUpBranchCode) =>
		tasks.push(
			bookingServiceGetAvailability({
				...params,
				pickUpBranchCode,
				dropOffBranchCode: pickUpBranchCode,
			}),
		),
	);

	const requestResponses: ServiceResponse<IAvailabilityAndPrice>[] = await Promise.all(tasks);

	// Map response to response action model
	const payload: IAvailabilityAndPriceByBranch[] = [];
	requestResponses.forEach((response, index) => {
		const branch = nearbyBranches[index];

		if (response.ok && response.data) {
			const groups = getMappedAndFilterVehicleGroupsAvailability(
				response.data.vehicleGroupsAvailability,
				params.creationMethod,
			);

			if (groups.length > 0) {
				payload.push({
					branch,
					groups,
				});
			}
		}
	});

	return new ServiceResponse<IAvailabilityAndPriceByBranch[]>(true, payload, []);
};

/**
 * Sign and register contract
 */
export const bookingSignAndRegisterContract = async (params: IBookingSignAndRegisterContractParams) => {
	const { bookingNumber, computerName, ip, requiredSign } = params;

	if (requiredSign && computerName && ip) {
		// 1 Firma el contrato
		const signResponse = await bookingServiceSignContract({
			bookingNumber,
			computerName,
			ip,
		});

		// 1.1 Si hay error retorna error
		if (!signResponse.ok) {
			return signResponse;
		}

		// 2. Acepta GDPR
		const customerAcceptGdprResponse = await customerServiceAcceptGdpr({ bookingNumber, computerName, ip });

		// 2.1 Si hay error retorna error
		if (!customerAcceptGdprResponse.ok) {
			return customerAcceptGdprResponse;
		}
	}

	// 3. Registra contrato
	const registerContractResponse = await bookingServiceRegisterContract({
		bookingNumber,
	});

	return registerContractResponse;
};

export const bookingServiceGetByAccidentPartData = (
	model: IBookingGetByAccidentDataParams,
): Promise<ServiceResponse<IBooking | null>> => {
	return Api.get<IBooking | null, IBookingGetByAccidentDataParams>(BookingEndPoints.GET_BY_ACCIDENT_PART_DATA, model);
};

/* eslint-disable react/button-has-type */
import * as React from 'react';

import { Slot } from '@radix-ui/react-slot';

import type { ButtonProps } from './types';
import { buttonVariants } from './utils';
import { cn } from '../lib/utils/tailwindClass';

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, color, size, isBlock, isDisabled, asChild = false, ...props }, ref) => {
		const Comp = asChild ? Slot : 'button';
		const classes = cn(className, { 'tw-w-full': isBlock });

		let buttonType = props.type || 'submit';

		if (props.onClick) {
			buttonType = 'button';
		}

		return (
			<Comp
				{...props}
				className={cn(buttonVariants({ variant: color, size, className: classes }))}
				disabled={isDisabled}
				ref={ref}
				type={buttonType}
			/>
		);
	},
);

Button.displayName = 'Button';

export { Button };

import React from 'react';

import classNames from 'classnames';
import type ReactHookForm from 'react-hook-form';

import { FieldError } from './FieldError';

export interface IFieldLabelProps {
	// The id of the input element for htmlFor label
	id: string;
	// The name of the input element
	name: string;
	// The label text
	label: string;
	// Whether the field is required
	required?: boolean;
	// The type of the input element
	type?: string;
	// The children element/s
	children?: React.ReactNode;
	// The error message
	error?: ReactHookForm.FieldError;
	// The extra class name for the label
	className?: string;
	// the role of the input
	role?: string;
}

/**
 * Field label component
 * @param param0 - The properties of the FieldLabel component
 */
export const FieldLabel: React.FC<IFieldLabelProps> = ({
	id,
	name,
	label,
	required,
	type,
	children,
	error,
	className,
	role,
}) => {
	const bold = required ? 'fw-bold' : undefined;

	// Render inline checkbox or radio
	if (type === 'checkbox' || type === 'radio') {
		// Apply switch styles if role is specified
		const isSwitch = role === 'switch';
		return (
			<div className={classNames('form-check', { 'form-switch': isSwitch }, className)}>
				{children}
				<label className={classNames('form-check-label mb-1', bold, { 'ms-2': isSwitch })} htmlFor={id}>
					{label}
				</label>
				<FieldError error={error} id={id} name={name} />
			</div>
		);
	}

	return (
		// Render default, input or select field
		<div className={classNames('tw-mb-3', className)}>
			{label ? (
				<label className={classNames('mb-1 w-100', bold)} htmlFor={id}>
					{label}
				</label>
			) : null}
			{children}
			<FieldError error={error} id={id} name={name} />
		</div>
	);
};

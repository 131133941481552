import { useEffect, useMemo, useState } from 'react';

import { type Action, type Dispatch, bindActionCreators } from '@reduxjs/toolkit';
import { useNavigate, useParams } from 'react-router-dom';

import { useManageRequestState } from '@crac/components/hooks/useManageRequestState';
import { FullPageLoader } from '@crac/components/intranet/shared/fullPageLoader';
import { getKeysFromEnum, getValueFromEnumKey } from '@crac/core/helpers/commons';
import { PartnerType } from '@crac/core/models/types/PartnerType';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { partnerAutoLogIn } from '@crac/core/redux/actions/PartnerActions';

import { routes, routesIdMap } from '~/config/routes';
import { useAppDispatch } from '~/redux/hooks';
import { usePartnerSelector, usePartnerState } from '~/redux/selectors/PartnerSelectors';
import { removeBrowserCache } from '~/serviceWorker';

import { useLoadCacheData } from './hooks';

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
	bindActionCreators(
		{
			clearGlobalCache,
			partnerAutoLogIn,
		},
		dispatch,
	);

const AutoLogin = () => {
	const params = useParams<{ id: string; type: string }>();
	const navigate = useNavigate();

	const partnerTypes = getKeysFromEnum(PartnerType);
	const validTyped = Boolean(params.type && params.id && partnerTypes.includes(params.type));

	const [loginSubmitted, setLoginSubmitted] = useState(false);

	const dispatch = useAppDispatch();
	const { clearGlobalCache, partnerAutoLogIn } = mapDispatchToProps(dispatch);

	const partner = usePartnerSelector();
	const { login: loginRequest } = usePartnerState();

	const { handleLoadCacheData } = useLoadCacheData();
	useManageRequestState(loginRequest, undefined, () => {
		navigate(routes[routesIdMap.Dashboard].path);
	});

	const isLoginSuccess = useMemo(
		() => !loginRequest.inProgress && loginRequest.ok && partner && loginSubmitted,
		[loginRequest, loginSubmitted, partner],
	);

	useEffect(() => {
		clearGlobalCache();

		sessionStorage.clear();
		localStorage.clear();

		removeBrowserCache();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!validTyped && routes[routesIdMap.Login]) {
			navigate(routes[routesIdMap.Login].path);
		}
	}, [navigate, validTyped]);

	useEffect(() => {
		if (!loginSubmitted && !loginRequest.inProgress) {
			setLoginSubmitted(true);
			partnerAutoLogIn({
				code: params.id as string,
				locale: 'es',
				type: getValueFromEnumKey(PartnerType, params.type as string),
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loginSubmitted, params.id, params.type, partner]);

	useEffect(() => {
		if (isLoginSuccess) {
			handleLoadCacheData();
			navigate(routes[routesIdMap.Dashboard].path);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoginSuccess, loginRequest.messages.length, loginSubmitted, navigate]);

	return <FullPageLoader isVisible={loginRequest.inProgress} />;
};

export default AutoLogin;

import { BookingLineEndPoints } from './BookingLineEndPoints';
import { Api } from '../../../shared/api';
import type { CreationMethod } from '../../../shared/types/CreationMethod';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IBookingLine } from '../entities/BookingLine';

export interface IBookingLineInsertParams {
	automatic: boolean;
	bookingLineType: number;
	bookingNumber?: string;
	code: string;
	creationMethod: CreationMethod;
	invoiceTo: string;
	invoiceToAgency: boolean;
	package: boolean;
	quoteDateTime: string | Date;
	serialNumber?: string;
}

export interface IBookingLineInsertListParams extends IServiceBaseParams {
	bookingLineList: IBookingLineInsertParams[];
}

export const bookingLineInsertService = (params: IBookingLineInsertListParams) => {
	return Api.post<IBookingLine[], IBookingLineInsertListParams>(BookingLineEndPoints.INSERT, params);
};

import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { FineEndPoints } from './FineEndpoints';
import type { IFine } from '../entities/Fine';

export interface IFineGetByIdParams extends IServiceBaseParams {
	id: string;
}

/**
 * Get fine by ID
 * @param {string} id fine ID
 */
export const fineGetByIdService = (model: IFineGetByIdParams): Promise<ServiceResponse<IFine>> => {
	return Api.get<IFine, IFineGetByIdParams>(FineEndPoints.GET_BY_ID, model);
};

import { DateTime } from 'luxon';

import { checkIsInvoiceableToAgency } from '../../business/booking/BookingInvoiceTo';
import type { IBooking } from '../../models/entities/Booking';
import type { IPaymentTransaction } from '../../models/entities/PaymentTransaction';
import type { IVehicleLine } from '../../models/entities/VehicleLine';
import type {
	IBookingCancelChangeOfVehicleParams,
	IBookingCancelParams,
	IBookingCancelSubscriptionParams,
	IBookingChangeDiscountParams,
	IBookingChangeVehicleOnContractParams,
	IBookingConfirmChangeOfVehicleParams,
	IBookingCustomerChangeParams,
	IBookingExtendParams,
	IBookingGetExtendAvailabilityParams,
	IBookingModifyParams,
	IBookingPaymentParams,
	IBookingStartChangeOfVehicleParams,
	IBookingStartChangeOfVehicleParamsAndInsertBookingLines,
} from '../../models/serviceParams/BookingModifyParams';
import type { IPaymentCallbackParams } from '../../models/serviceParams/PaymentParams';
import type { IBookingExtendResponse } from '../../models/serviceResponse/BookingExtendResponse';
import type { IBookingLine } from '../../modules/booking/bookingLine/entities/BookingLine';
import type { IBookingLineInsertParams } from '../../modules/booking/bookingLine/services/BookingLineInsertService';
import { bookingLineInsertService } from '../../modules/booking/bookingLine/services/BookingLineInsertService';
import { BookingLineType } from '../../modules/booking/bookingLine/types/BookingLineType';
import { Api } from '../../modules/shared/api';
import { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import type { IVehicle } from '../../modules/vehicle/entities/Vehicle';
import { BookingEndPoints } from '../endPoints/BookingEndPoints';

/**
 * @deprecated - use `bookingServiceCancel` from `packages\core\src\modules\booking\modify\services\BookingCancelService.ts` instead
 * Cancel Booking by number
 *  {string} bookingNumber Booking Numer
 * @returns {Promise<BookingPropType>} `BookingPropType`
 */
export const bookingServiceCancel = (model: IBookingCancelParams): Promise<ServiceResponse<IBooking>> => {
	return Api.post<IBooking, IBookingCancelParams>(BookingEndPoints.CANCEL, model);
};

/**
 * @deprecated - use `bookingChangeVehicleOnContractService` from `packages\core\src\modules\booking\modify\services\BookingChangeVehicleOnContractService.ts` instead
 * Change vehicle on contract
 *  {string} contractNumber Contract number
 *  {Vehicle} oldVehicle Old vehicle
 *  {Vehicle} newVehicle New vehicle
 * @returns {Promise<void>} `void`
 */
export const bookingServiceChangeVehicleOnContract = (
	model: IBookingChangeVehicleOnContractParams,
): Promise<ServiceResponse<IBooking>> => {
	return Api.post<IBooking, IBookingChangeVehicleOnContractParams>(BookingEndPoints.CHANGE_VEHICLE_CONTRACT, model);
};

/**
 * @deprecated - use `bookingStartChangeOfVehicleService` from `packages\core\src\modules\booking\modify\services\BookingStartChangeOfVehicleService.ts` instead
 */
export const bookingServiceStartChangeOfVehicle = (
	model: IBookingStartChangeOfVehicleParams,
): Promise<ServiceResponse<IVehicle>> => {
	return Api.post<IVehicle, IBookingStartChangeOfVehicleParams>(BookingEndPoints.START_CHANGE_OF_VEHICLE, model);
};

/**
 * @deprecated - use `bookingStartChangeOfVehicleAndInsertBookingLinesService` from `packages\core\src\modules\booking\modify\services\BookingStartChangeOfVehicleAndInsertBookingLinesService.ts instead
 */
export const bookingServiceStartChangeOfVehicleAndInsertBookingLines = async (
	params: IBookingStartChangeOfVehicleParamsAndInsertBookingLines,
): Promise<ServiceResponse<IVehicle>> => {
	const { booking, bookingLines, plateNumber, services, creationMethod } = params;

	const startChangeOfVehicleResponse = await bookingServiceStartChangeOfVehicle({
		bookingNumber: booking.bookingNumber,
		plateNumber,
	});

	if (services.length > 0) {
		const linesToInsert: IBookingLineInsertParams[] = [];
		services.forEach((service) => {
			const bookingLine = checkIsInvoiceableToAgency(service, bookingLines);

			linesToInsert.push({
				automatic: true,
				bookingLineType: BookingLineType.Service,
				bookingNumber: booking.bookingNumber,
				code: bookingLine.code,
				creationMethod,
				invoiceTo: bookingLine.invoiceToAgency ? booking.invoiceToAgencyCode : booking.invoiceToCustomerCode,
				invoiceToAgency: Boolean(bookingLine.invoiceToAgency),
				package: Boolean(bookingLine.package),
				quoteDateTime: DateTime.local().toJSDate(),
			});
		});

		const bookingLineInsertResponse = await bookingLineInsertService({
			bookingLineList: linesToInsert,
		});

		if (!bookingLineInsertResponse.ok) {
			return new ServiceResponse<IVehicle>(false, null, bookingLineInsertResponse.errors);
		}
	}

	return startChangeOfVehicleResponse;
};

/**
 * @deprecated - use `bookingConfirmChangeOfVehicleService` from `packages\core\src\modules\booking\modify\services\BookingConfirmChangeOfVehicleService.ts` instead
 */
export const bookingServiceConfirmChangeOfVehicle = (
	model: IBookingConfirmChangeOfVehicleParams,
): Promise<ServiceResponse<IVehicleLine[]>> => {
	return Api.post<IVehicleLine[], IBookingConfirmChangeOfVehicleParams>(
		BookingEndPoints.CONFIRM_CHANGE_OF_VEHICLE,
		model,
	);
};

/**
 * @deprecated - use `bookingConfirmChangeOfVehicleService` from `packages\core\src\modules\booking\modify\services\BookingConfirmChangeOfVehicleService.ts` instead
 */
export const bookingServiceCancelChangeOfVehicle = (
	model: IBookingCancelChangeOfVehicleParams,
): Promise<ServiceResponse<IVehicleLine>> => {
	return Api.post<IVehicleLine, IBookingCancelChangeOfVehicleParams>(
		BookingEndPoints.CANCEL_CHANGE_OF_VEHICLE,
		model,
	);
};

/**
 * @deprecated - use `bookingGetExtendAvailabilityService` from `packages\core\src\modules\booking\modify\services\BookingGetExtendAvailabilityService.ts` instead
 */
export const bookingServiceGetExtendAvailability = (
	model: IBookingGetExtendAvailabilityParams,
): Promise<ServiceResponse<IBookingLine[]>> => {
	return Api.get<IBookingLine[], IBookingGetExtendAvailabilityParams>(
		BookingEndPoints.GET_EXTEND_AVAILABILITY,
		model,
	);
};

/**
 * @deprecated - use `bookingExtendService` from `packages\core\src\modules\booking\modify\services\BookingExtendService.ts` instead
 */
export const bookingServiceExtend = (model: IBookingExtendParams): Promise<ServiceResponse<IBookingExtendResponse>> => {
	return Api.post<IBookingExtendResponse, IBookingExtendParams>(BookingEndPoints.EXTEND, model);
};

/**
 * @deprecated - use `bookingExtendCallBackService` from `packages\core\src\modules\booking\modify\services\BookingExtendService.ts` instead
 */
export const bookingServiceExtendCallBack = (
	model: IPaymentCallbackParams,
): Promise<ServiceResponse<IBookingExtendResponse>> => {
	return Api.post<IBookingExtendResponse, IPaymentCallbackParams>(BookingEndPoints.EXTEND_CALLBACK, model);
};
/**
 * @deprecated - use `bookingModifyService` from `packages\core\src\modules\booking\modify\services\BookingModifyService.ts` instead
 * Modify booking data
 *  {Object} BookingPropType booking
 * @returns {Promise<BookingPropType>} `BookingPropType`
 */
export const bookingServiceModify = (model: IBookingModifyParams): Promise<ServiceResponse<IBooking>> => {
	return Api.post<IBooking, IBookingModifyParams>(BookingEndPoints.MODIFY, model);
};

/**
 * @deprecated - use `bookingChangeDiscountService` from `packages\core\src\modules\booking\modify\services\BookingChangeDiscountService.ts` instead
 */
export const bookingServiceChangeDiscount = (model: IBookingChangeDiscountParams): Promise<ServiceResponse<number>> => {
	return Api.post<number, IBookingChangeDiscountParams>(BookingEndPoints.CHANGE_DISCOUNT, model);
};

/**
 * @deprecated - use `bookingCustomerChangeService` from `packages\core\src\modules\booking\modify\services\BookingCustomerChangeService.ts` instead
 */
export const bookingServiceCustomerChange = (
	model: IBookingCustomerChangeParams,
): Promise<ServiceResponse<IBooking>> => {
	return Api.post<IBooking, IBookingCustomerChangeParams>(BookingEndPoints.CHANGE_CUSTOMER, model);
};
/**
 * @deprecated - use `bookingPaymentService` from `packages\core\src\modules\booking\modify\services\BookingPaymentService.ts` instead
 */
export const bookingServicePayment = (model: IBookingPaymentParams): Promise<ServiceResponse<IPaymentTransaction>> => {
	return Api.post<IPaymentTransaction, IBookingPaymentParams>(BookingEndPoints.PAYMENT, model);
};

/**
 * @deprecated - use 'bookingPaymentCallBackService' from 'packages\core\src\modules\booking\modify\services\BookingPaymentCallBackService.ts' instead.
 */
export const bookingServicePaymentCallBack = (
	model: IPaymentCallbackParams,
): Promise<ServiceResponse<IPaymentTransaction>> => {
	return Api.post<IPaymentTransaction, IPaymentCallbackParams>(BookingEndPoints.PAYMENT_CALLBACK, model);
};

/**
 * @deprecated - use `bookingCancelSubscriptionService` from `packages\core\src\modules\booking\modify\services\BookingCancelSubscriptionService.ts` instead
 */
export const bookingServiceCancelSubscription = (
	model: IBookingCancelSubscriptionParams,
): Promise<ServiceResponse<IBooking>> => {
	return Api.post<IBooking, IBookingCancelParams>(BookingEndPoints.CANCEL_SUBSCRIPTION, model);
};

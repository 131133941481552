/* eslint-disable complexity */
import { getBookingLineFromService } from './BookingLines';
import type { IAgency } from '../../models/entities/Agency';
import type { IVehicleGroupAvailabilityAndPrice } from '../../models/entities/Availability';
import type { IBookingPackage } from '../../models/entities/BookingPackage';
import type { ICompany } from '../../models/entities/Company';
import type { ICustomer } from '../../models/entities/Customer';
import type { IProvider } from '../../models/entities/Provider';
import type { IService } from '../../models/entities/Service';
import { BookingType } from '../../models/types/BookingType';
import { InvoiceToCustomerType } from '../../models/types/InvoiceToCustomerType';
import type { IBookingLine } from '../../modules/booking/bookingLine/entities/BookingLine';
import { BookingLineType } from '../../modules/booking/bookingLine/types/BookingLineType';
import type { IBookingNewReducerState } from '../../redux-store/reducersState/booking/BookingNewReducerState';
/**
 * Establece el invoiceToAgency, basado en `invoiceableQuantity` del servicio y las líneas actuales de la reserva.
 */
export const checkIsInvoiceableToAgency = (service: IService, bookingLines: IBookingLine[]): IBookingLine => {
	const addedLines = bookingLines.filter((line) => line.code === service.code && line.invoiceToAgency);

	const invoiceToAgency = addedLines.length < service.invoiceableQuantity;

	return {
		...getBookingLineFromService(bookingLines, service),
		invoiceToAgency,
	};
};

/**
 * Set invoice to in bookingLine
 * @param provider Current provider
 * @param bookingLine booking line to set invoice to
 * @param agency current agency
 * @param group booking vehicle group
 * @param currentLines current added lines in booking
 */
const setInvoiceToAgency = (
	bookingLine: IBookingLine,
	currentLines: IBookingLine[],
	state: IBookingNewReducerState,
	bookingPackage?: IBookingPackage | null,
): IBookingLine => {
	const { customer, bookingType } = state;
	const agency = state.agency as IAgency;
	const provider = state.currentProvider as IProvider;
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const group = state.group as IVehicleGroupAvailabilityAndPrice;
	const { genericCustomerCode } = provider;

	const isGcAgency = agency.centauroAgency;
	const isGenericCustomer = customer && customer.code === genericCustomerCode;
	const customerCode = customer && !isGenericCustomer ? customer.code : genericCustomerCode;
	const { invoiceToAgency } = bookingLine;

	const lineInvoiceToAgency = {
		...bookingLine,
		invoiceTo: agency.invoiceTo || agency.code,
		invoiceToAgency: true,
		invoiceToCustomerType: InvoiceToCustomerType.AGENCY,
	};

	const lineInvoiceToCustomer = {
		...bookingLine,
		invoiceTo: customerCode,
		invoiceToAgency: false,
		invoiceToCustomerType: isGenericCustomer ? InvoiceToCustomerType.GENERIC : InvoiceToCustomerType.CUSTOMER,
	};

	// VehicleGroup to agency if no is gc
	if (!isGcAgency && bookingLine.bookingLineType === BookingLineType.VehicleGroup) {
		return lineInvoiceToAgency;
	}

	// TODO: SEPARATE use case
	if (
		(isGcAgency ||
			bookingLine.bookingLineType !== BookingLineType.VehicleGroup ||
			[BookingType.TRANSFER, BookingType.STAFF].includes(bookingType as BookingType)) &&
		(!invoiceToAgency || (bookingLine.package && isGcAgency)) &&
		(!bookingLine.package || (bookingLine.package && isGcAgency))
	) {
		return lineInvoiceToCustomer;
	}

	// Si es de paquete
	if (bookingLine.package) {
		if (bookingPackage && bookingPackage.services.length > 0) {
			const packageService = bookingPackage.services.find((bLine) => bLine.code === bookingLine.code);
			if (packageService && packageService.invoiceable) {
				return lineInvoiceToAgency;
			}
		}
	}

	return lineInvoiceToAgency;
};

/**
 * Set invoice to based in current customer value
 * @param bookingLine BookingLine
 * @param provider Currengo booking new state provider
 * @param customer current customer in booking state
 */
const setInvoiceToCustomer = (
	bookingLine: IBookingLine,
	provider: IProvider,
	customer?: ICustomer | null,
): IBookingLine => {
	const { genericCustomerCode } = provider;
	let invoiceToCustomerType = InvoiceToCustomerType.GENERIC;
	let invoiceTo = genericCustomerCode;

	if (customer && customer.code !== genericCustomerCode) {
		invoiceTo = customer.code;
		invoiceToCustomerType = InvoiceToCustomerType.CUSTOMER;
	}

	return {
		...bookingLine,
		invoiceTo,
		invoiceToAgency: false,
		invoiceToCustomerType,
	};
};

/**
 * Set invoice to base in company configuration
 * @param bookingLine BookingLine
 * @param provider Current booking new state provider
 * @param company Current company booing new state
 * @param customer Current customer booing new state
 */
export const setInvoiceToCompany = (
	bookingLine: IBookingLine,
	provider: IProvider,
	company: ICompany,
	customer?: ICustomer | null,
	rentInvoiceToAgency = false,
): IBookingLine => {
	const { genericCustomerCode } = provider;
	const customerCode = customer && customer.code !== genericCustomerCode ? customer.code : genericCustomerCode;
	const {
		directPaymentDeposit,
		directPaymentExtras,
		directPaymentFranchise,
		directPaymentFuel,
		directPaymentKMExceeded,
		directPaymentRent,
	} = company;

	if (bookingLine.bookingLineType === BookingLineType.VehicleGroup) {
		return {
			...bookingLine,
			invoiceTo: directPaymentRent ? customerCode : company.code,
			invoiceToAgency: !directPaymentRent,
			invoiceToCustomerType: directPaymentRent ? InvoiceToCustomerType.CUSTOMER : InvoiceToCustomerType.COMPANY,
		};
	}

	if (bookingLine.bookingLineType === BookingLineType.Deposit) {
		return {
			...bookingLine,
			invoiceTo: directPaymentDeposit ? customerCode : company.code,
			invoiceToAgency: !directPaymentDeposit,
			invoiceToCustomerType: directPaymentDeposit
				? InvoiceToCustomerType.CUSTOMER
				: InvoiceToCustomerType.COMPANY,
		};
	}

	if (bookingLine.bookingLineType === BookingLineType.Franchise) {
		return {
			...bookingLine,
			invoiceTo: directPaymentFranchise ? customerCode : company.code,
			invoiceToAgency: !directPaymentFranchise,
			invoiceToCustomerType: directPaymentFranchise
				? InvoiceToCustomerType.CUSTOMER
				: InvoiceToCustomerType.COMPANY,
		};
	}

	if (bookingLine.bookingLineType === BookingLineType.Fuel) {
		return {
			...bookingLine,
			invoiceTo: directPaymentFuel ? customerCode : company.code,
			invoiceToAgency: !directPaymentFuel,
			invoiceToCustomerType: directPaymentFuel ? InvoiceToCustomerType.CUSTOMER : InvoiceToCustomerType.COMPANY,
		};
	}

	if (bookingLine.bookingLineType === BookingLineType.KM) {
		return {
			...bookingLine,
			invoiceTo: directPaymentKMExceeded ? customerCode : company.code,
			invoiceToAgency: !directPaymentKMExceeded,
			invoiceToCustomerType: directPaymentKMExceeded
				? InvoiceToCustomerType.CUSTOMER
				: InvoiceToCustomerType.COMPANY,
		};
	}

	/**
	 * si directPaymentExtras manda lo que llegue en cada extra
	 * si !directPaymentExtras se ignora lo que haya a nivel de extra
	 */

	let extrasInvoiceToAgency = !directPaymentExtras;
	if (bookingLine.package) {
		extrasInvoiceToAgency = rentInvoiceToAgency;
	}

	/**
	 * SI PAGA LA AGENCIA
	 */
	if (extrasInvoiceToAgency) {
		return {
			...bookingLine,
			invoiceTo: company.code,
			invoiceToAgency: extrasInvoiceToAgency,
			invoiceToCustomerType: InvoiceToCustomerType.COMPANY,
		};
	}

	/**
	 * SI PAGA EL CLIENTE
	 */
	return {
		...bookingLine,
		invoiceTo: customerCode,
		invoiceToCustomerType: InvoiceToCustomerType.CUSTOMER,
	};
};

/**
 * Set default invoiceTo in booking lines
 * @param state Booking new reducer state
 * @param bookingLines booking lines in booking
 */
export const setBookingLinesInvoiceTo = (
	state: IBookingNewReducerState,
	bookingLines: IBookingLine[],
	bookingPackage?: IBookingPackage | null,
): IBookingLine[] => {
	const { currentProvider, bookingType, customer, company } = state;

	// INVOICEABLE TO AGENCY
	if (bookingType === BookingType.PREBOOKING) {
		return bookingLines.map((line) => setInvoiceToAgency(line, bookingLines, state, bookingPackage));
	}

	// INVOICEABLE TO COMPANY
	if (bookingType === BookingType.COMPANY) {
		const [rentLine] = bookingLines.filter((line) => line.bookingLineType === BookingLineType.VehicleGroup);
		const rentBookingLine = setInvoiceToCompany(
			rentLine,
			currentProvider as IProvider,
			company as ICompany,
			customer,
		);

		return bookingLines.map((line) =>
			setInvoiceToCompany(
				line,
				currentProvider as IProvider,
				company as ICompany,
				customer,
				rentBookingLine.invoiceToAgency,
			),
		);
	}

	// INVOICEABLE TO CUSTOMER
	return bookingLines.map((line) => setInvoiceToCustomer(line, currentProvider as IProvider, customer));
};

'use client';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { TooltipContent } from './components/TooltipContent';

const Tooltip = TooltipPrimitive.Root;

const TooltipProvider = TooltipPrimitive.Provider;

const TooltipTrigger = TooltipPrimitive.Trigger;

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider };

import * as React from 'react';

import * as SheetPrimitive from '@radix-ui/react-dialog';
import { LuX } from 'react-icons/lu';

import { SheetOverlay } from './SheetOverlay';
import { cn } from '../../lib/utils/tailwindClass';
import type { SheetContentProps } from '../types';
import { sheetVariants } from '../variants';

export const SheetContent = React.forwardRef<React.ElementRef<typeof SheetPrimitive.Content>, SheetContentProps>(
	({ side = 'right', className, children, ...props }, ref) => (
		<SheetPrimitive.Portal>
			<SheetOverlay />
			<SheetPrimitive.Content className={cn(sheetVariants({ side }), className)} ref={ref} {...props}>
				{children}
				<SheetPrimitive.Close className="sheet-content-close">
					<LuX className="tw-h-4 tw-w-4" />
					<span className="tw-sr-only">Close</span>
				</SheetPrimitive.Close>
			</SheetPrimitive.Content>
		</SheetPrimitive.Portal>
	),
);

SheetContent.displayName = SheetPrimitive.Content.displayName;

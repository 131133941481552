import type { FC } from 'react';
import React from 'react';

import { Card, CardContent } from '@crac/ui/card';
import { cn } from '@crac/ui/lib/utils/tailwindClass';

export const LoginContainer: FC<{ children: React.ReactNode; className?: string }> = ({ children, className }) => (
	<div className={cn('tw-w-full tw-flex tw-justify-center tw-items-center', className)}>
		<Card className="tw-mb-0 tw-w-[350px]">
			<CardContent className="tw-p-4">{children}</CardContent>
		</Card>
	</div>
);

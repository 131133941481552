import * as React from 'react';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { cn } from '../../lib/utils/tailwindClass';

export const TooltipContent = React.forwardRef<
	React.ElementRef<typeof TooltipPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
	<TooltipPrimitive.Content className={cn('tw-tooltip', className)} ref={ref} sideOffset={sideOffset} {...props}>
		{props.children}
		<TooltipPrimitive.Arrow className="tw-fill-black/80 dark:tw-fill-dark" />
	</TooltipPrimitive.Content>
));

TooltipContent.displayName = TooltipPrimitive.Content.displayName;

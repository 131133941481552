import { FineEndPoints } from './FineEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IFine } from '../entities/Fine';
import type { FineFile } from '../types/FineFileType';

export interface IFineModifyParams extends IServiceBaseParams {
	code: string;
	bookingNumber?: string;
	plateNumber: string;
	dateTime: string | Date;
	fineReason: number;
	fineTransmitter: string;
	expedient: string;
	toPay?: boolean;
	fineAmountToPayCentauro?: number;
	comment?: string;
	state: number;
	files?: FineFile[];
}

/**
 * Modify a fine
 * @return {Promise<FineType>} `FineType`
 */
export const fineModifyService = (model: IFineModifyParams): Promise<ServiceResponse<IFine>> => {
	return Api.post<IFine, IFineModifyParams>(FineEndPoints.MODIFY, model);
};

/* eslint-disable react/no-unused-prop-types */
import type { ComponentProps } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { type Action, type Dispatch, bindActionCreators } from '@reduxjs/toolkit';
import type { Location as LocationType } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';

import { useManageRequestState } from '@crac/components/hooks/useManageRequestState';
import { Login } from '@crac/components/intranet/shared/login';
import { Modal } from '@crac/components/intranet/shared/modal';
import { clearGlobalCache, setLocale } from '@crac/core/redux/actions/CommonActions';
import { partnerLogIn } from '@crac/core/redux/actions/PartnerActions';

import { PrivacyPolicy } from '~/components/PrivacyPolicy';
import { routes, routesIdMap } from '~/config/routes';
import { useAppDispatch } from '~/redux/hooks';
import { useCommonsLocaleSelector } from '~/redux/selectors/CommonsSelectors';
import { usePartnerSelector, usePartnerState } from '~/redux/selectors/PartnerSelectors';
import { removeBrowserCache } from '~/serviceWorker';

import { useLoadCacheData } from './hooks';

type LoginComponentProps = ComponentProps<typeof Login>;
type stateFromType = { from: LocationType };

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
	bindActionCreators(
		{
			clearGlobalCache,
			partnerLogIn,
			setLocale,
		},
		dispatch,
	);

const LoginPage = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const [loginSubmitted, setLoginSubmitted] = useState(false);
	const [openModal, setOpenModal] = useState(false);

	const dispatch = useAppDispatch();
	const { partnerLogIn, clearGlobalCache, setLocale } = mapDispatchToProps(dispatch);

	const { login: loginRequest } = usePartnerState();
	const partner = usePartnerSelector();
	const { locale } = useCommonsLocaleSelector();

	const { handleLoadCacheData } = useLoadCacheData();
	useManageRequestState(loginRequest);

	/**
	 * Submit login request
	 */
	const handleSubmit: LoginComponentProps['onSubmit'] = useCallback(({ password, userName }) => {
		if (userName && password) {
			partnerLogIn({ password, userName });
			setLoginSubmitted(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!locale) {
			setLocale('es');
		}

		clearGlobalCache();

		sessionStorage.clear();
		localStorage.clear();

		removeBrowserCache();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Check login request status
	 */
	useEffect(() => {
		const isLoginSuccess = loginSubmitted && !loginRequest.inProgress && loginRequest.ok && partner;
		if (isLoginSuccess) {
			handleLoadCacheData();

			// CHECK CACHE DATA LOADED
			const state = location.state as stateFromType;

			if (state && state.from && state.from.pathname) {
				const { pathname, search } = state.from;
				navigate(search ? `${pathname}?${search}` : pathname, { replace: true });
			} else {
				navigate(routes[routesIdMap.Dashboard].path);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate, loginRequest, partner, location, loginSubmitted]);

	return (
		<div className="app tw-flex tw-items-center tw-justify-center" style={{ minWidth: '300px' }}>
			<Login
				inputs={{
					inputPassword: { name: 'password', placeholder: t`Password` },
					inputUserName: { name: 'userName', placeholder: t`Use your code` },
				}}
				locale={locale}
				onChangeLanguage={setLocale}
				onShowPrivacyPolicy={() => setOpenModal(true)}
				onSubmit={handleSubmit}
				showSwitchLanguage
				submitButton={{
					block: true,
				}}
				subtitle={t`Partner access`}
			/>
			<Modal isOpen={openModal} onClose={() => setOpenModal(false)} type="slide">
				<PrivacyPolicy locale={locale} />
			</Modal>
		</div>
	);
};

export default LoginPage;

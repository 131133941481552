import React from 'react';

import { cn } from '../../lib/utils/tailwindClass';

export const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
	({ className = 'tw-flex tw-items-center', ...props }, ref) => (
		<div className={cn('tw-card-header', className)} ref={ref} {...props} />
	),
);

CardHeader.displayName = 'CardHeader';

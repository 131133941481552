import { Trans } from '@lingui/macro';

export const Footer = (): JSX.Element => {
	return (
		<footer className="tw-flex tw-justify-center tw-items-center tw-h-12 tw-px-4 tw-text-[#4f5d73] tw-text-center">
			&copy; Copyright {new Date().getFullYear()}
			<a href="https://centauro.net" rel="noopener noreferrer" target="_blank">
				&nbsp;Centauro Rent A Car&nbsp;
			</a>
			<Trans>All rights reserved.</Trans>
		</footer>
	);
};

import { BookingLineEndPoints } from './BookingLineEndPoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IBookingLine } from '../entities/BookingLine';

export interface IBookingLineGetByBookingParams extends IServiceBaseParams {
	bookingNumber: string;
}

export const bookingLineGetByBookingService = (params: IBookingLineGetByBookingParams) => {
	return Api.get<IBookingLine[], IBookingLineGetByBookingParams>(BookingLineEndPoints.GET_BY_BOOKING, params);
};

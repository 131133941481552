'use client';

import type { ElementType, FC } from 'react';
import React, { useMemo, useState } from 'react';

import { FaChevronDown } from 'react-icons/fa6';

import { SidebarListItemSubmenu } from './SidebarListItemSubmenu';
import { cn } from '../../lib/utils/tailwindClass';
import { useMediaQuery } from '../../lib/utils/useMediaQuery';
import type { INavItem } from '../types';

interface ISidebarListItemProps extends INavItem {
	expandSidebar?: boolean;
	AnchorComponent?: ElementType;
}

// Helper function to determine which component to use
const getComponent = (
	children: ISidebarListItemProps['children'],
	AnchorComponent?: ISidebarListItemProps['AnchorComponent'],
) => {
	if (children) {
		return 'div';
	}
	if (AnchorComponent) {
		return AnchorComponent;
	}
	return 'a';
};

export const SidebarListItem: FC<ISidebarListItemProps> = ({
	icon: Icon,
	url,
	name,
	children,
	active,
	expandSidebar,
	AnchorComponent,
}) => {
	// HOOKS
	const isMobile = useMediaQuery('lg');
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	// MEMOS
	const mobileOrExpanded = useMemo(() => isMobile || expandSidebar, [isMobile, expandSidebar]);
	const notMobileAndNotExpanded = useMemo(() => !isMobile && !expandSidebar, [isMobile, expandSidebar]);
	const anyChildrenIsActive = useMemo(() => children?.some((child) => child.active === true), [children]);

	const Comp = useMemo(() => getComponent(children, AnchorComponent), [children, AnchorComponent]);

	return (
		<li className="tw-group sidebar-list-item">
			{mobileOrExpanded && children ? (
				<div
					className={cn(
						'sidebar-list-item-link',
						(active || anyChildrenIsActive) && 'sidebar-item-active',
						isMenuOpen && 'tw-bg-warning tw-text-primary',
					)}
					onClick={() => setIsMenuOpen((value) => !value)}
				>
					<div className="tw-flex tw-items-center tw-gap-4">
						{Icon ? <Icon /> : null}
						{mobileOrExpanded ? name : null}
					</div>
					<FaChevronDown
						className={cn(
							'tw-transition-transform tw-duration-300 tw-text-gray-400',
							isMenuOpen ? 'tw-rotate-180' : 'tw-rotate-0',
						)}
					/>
				</div>
			) : (
				<Comp
					className={cn('sidebar-list-item-link', (active || anyChildrenIsActive) && 'sidebar-item-active')}
					href={url}
					to={url}
				>
					<div className="tw-flex tw-items-center tw-gap-4">
						{Icon ? <Icon size={expandSidebar ? 15 : 19} /> : null}
						{mobileOrExpanded ? name : null}
					</div>
				</Comp>
			)}
			<div className={cn('sidebar-list-item-container', notMobileAndNotExpanded && 'item-not-expanded')}>
				{notMobileAndNotExpanded ? (
					<Comp className="sidebar-list-item-container-link" href={url} to={url}>
						{name}
					</Comp>
				) : null}
				<SidebarListItemSubmenu
					AnchorComponent={AnchorComponent}
					isMenuOpen={isMenuOpen}
					isMobile={mobileOrExpanded}
					items={children}
				/>
			</div>
		</li>
	);
};

SidebarListItem.displayName = 'SidebarListItem';

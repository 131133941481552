/**
 * @deprecated - use `DocumentEndPoints` from 'packages\core\src\modules\documentation\services\DocumentEndPoints.ts' instead.
 */
export enum DocumentEndPoints {
	/**
	 *
	 * @host `/document/documentpdf/`
	 */
	DOCUMENT_PDF = '/document/documentpdf/',

	/**
	 * Documents get by booking
	 * @host `/document/getbybooking/`
	 */
	GET_BY_BOOKING = '/document/getbybooking/',
}

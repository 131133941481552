/* eslint-disable no-underscore-dangle */
let HOSTNAME = 'localhost';
const isClientSide = typeof window !== 'undefined' && Boolean(window?.location);

if (isClientSide) {
	HOSTNAME = location.hostname.toLocaleLowerCase();
}

const productionHosts = [
	'rac.centauro.net',
	'partners.centauro.net',
	'www.centauro.net',
	'centauro.net',
	'test-web-mutua.centauro.net',
];
/**
 * Production based on hostname
 * This var determines if use production or development endpoints base on domain name.
 * Only use this var on client side.
 */
export const isProduction = productionHosts.includes(HOSTNAME);

export const CONTENT_API_PRODUCTION = 'https://content-api.centauro.net';
export const CONTENT_API_DEVELOPMENT = 'https://content-apitest.centauro.net';
export const API_PRODUCTION = 'https://api.centauro.net';
export const API_DEVELOPMENT = 'https://apitest.centauro.net';

/**
 * Añado el host como un parámetro opcinal para poder pasarlo en los tests
 */
export const getContentApiEndPoint = (isBrowser = isClientSide, host = HOSTNAME) => {
	// SI es en local no hacemos caso a esta variable para evitar llamar a real
	if (typeof process !== 'undefined' && process?.env?.API_URL) {
		// eslint-disable-next-line no-console
		console.log({ API_URL: process.env.API_URL });
		return process.env.API_URL;
	}

	// CLIENT SIDE
	if (isBrowser) {
		if (productionHosts.includes(host)) {
			return CONTENT_API_PRODUCTION;
		}

		return CONTENT_API_DEVELOPMENT;
	}

	/**
	 * BUILD DOCKER IMAGE
	 *
	 * Use when build docker image, only check APP_ENV variable
	 * APP_ENV=production -> para hacer la imagen apuntando a producción
	 * APP_ENV=development -> para hacer la imagen apuntando a desarrollo
	 */
	if (process.env.APP_ENV === 'production') {
		return CONTENT_API_PRODUCTION;
	}

	if (process.env.APP_ENV === 'development') {
		return CONTENT_API_DEVELOPMENT;
	}

	/**
	 * NODE_ENV, only use on server side request
	 * Example:
	 * - SSR production
	 * - react native production
	 */
	if (process.env.NODE_ENV === 'production') {
		return CONTENT_API_PRODUCTION;
	}

	if (process.env.NODE_ENV === 'development') {
		return CONTENT_API_DEVELOPMENT;
	}

	return '';
};

const API_CONTENT_SERVER_ENDPOINT = getContentApiEndPoint();

// Only use on client side
export const API_SERVER_ENDPOINT = isProduction ? API_PRODUCTION : API_DEVELOPMENT;

export const API_ENDPOINT = `${API_SERVER_ENDPOINT}/api`;
export const API_CONTENT_ENDPOINT = `${API_CONTENT_SERVER_ENDPOINT}/api`;

export const REALTIME_ENDPOINT = isProduction ? `${API_SERVER_ENDPOINT}/realtime` : `${API_SERVER_ENDPOINT}/realtime`;
export const TV_TURNS_ENDPOINT = isProduction ? 'https://queues.centauro.net/' : 'http://queues.grupocentauro.com/';
export const TPV_ENDPOINT = isProduction
	? 'http://tpv.grupocentauro.com/tpv/ticket'
	: 'http://tpvtest.grupocentauro.com/tpv/ticket';

export const ZAMMAD_CMS_URL = isProduction ? 'https://service.centauro.net/' : 'https://zammad-test.centauro.net';
export const NOTIFICATIONS_API_URL = isProduction
	? 'https://notifications-api.centauro.net/api'
	: 'http://notifications-api-test.grupocentauro.com/api';

import type { FC } from 'react';
import React, { useEffect } from 'react';

import { t } from '@lingui/macro';

import { FaArrowRight } from '@crac/ui/icons';

interface ILanguageSwitchProps {
	locale?: string;
	setLocale?: (locale: string) => void;
}

export const LanguageSwitch: FC<ILanguageSwitchProps> = ({ locale, setLocale }) => {
	const localeToChange = locale === 'es' ? 'en' : 'es';

	const handleSetLocale = (event: React.MouseEvent<any, MouseEvent>): void => {
		event.preventDefault();

		if (setLocale) {
			setLocale(localeToChange);
		}
	};

	useEffect(() => {
		if (setLocale) {
			setLocale(locale as string);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="tw-bg-[#ffe396] tw-text-[#001e96] tw-h-full -tw-mr-4">
			<button
				className="tw-flex tw-items-center tw-h-full tw-px-2 tw-cursor-pointer"
				onClick={handleSetLocale}
				title={t`Switch language`}
				type="button"
			>
				<span className="tw-font-bold">{locale ? locale.toLocaleUpperCase() : null}</span>
				<FaArrowRight className="tw-inline-block tw-mx-1" />
				{localeToChange.toLocaleUpperCase()}
			</button>
		</div>
	);
};

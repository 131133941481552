import type { FC } from 'react';
import { useEffect, useMemo } from 'react';

import { type Action, type Dispatch, bindActionCreators } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { FullPageLoader } from '@crac/components/intranet/shared/fullPageLoader/index';
import type { IPartner } from '@crac/core/models/entities/Partner';
import { isProduction } from '@crac/core/modules/shared/api/ApiEndPoint';
import { setLocale } from '@crac/core/redux/actions/CommonActions';
import { Container } from '@crac/ui/container';
import { Sidebar } from '@crac/ui/sidebar';

import { useLayoutState } from '~/components/Layout/hooks/useLayoutState';
import { navigationItems } from '~/config/menuItems';
import { routes, routesIdMap } from '~/config/routes';
import { useSignalR } from '~/hooks/useSignalR';
import { usePartnerSelector, useProvidersSelector } from '~/hooks/useStateSelectors';
import { useAppDispatch } from '~/redux/hooks';
import { useCommonsLocaleSelector, useLoaderSelector } from '~/redux/selectors/CommonsSelectors';
import { removeBrowserCache } from '~/serviceWorker';

import { Footer } from './Footer';
import { AppHeader } from './Header';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
	bindActionCreators(
		{
			setLocale,
		},
		dispatch,
	);

export const Layout: FC = (): JSX.Element => {
	const partner = usePartnerSelector();
	const loader = useLoaderSelector();
	const { locale } = useCommonsLocaleSelector();
	const providers = useProvidersSelector();

	const dispatch = useAppDispatch();

	const { setLocale } = mapDispatchToProps(dispatch);

	const location = useLocation();
	const history = useNavigate();
	const hubConnection = useSignalR(partner.token);

	const { sidebarOpen, setSidebarOpen, mobileSidebarOpen, setMobileSidebarOpen } = useLayoutState(location);

	const menuItems = useMemo(
		() => navigationItems(partner, providers, partner.permissions, location),
		[partner, providers, location],
	);

	const updateAllBrowsers = () => {
		window.location.reload();
	};

	useEffect(() => {
		const launchScript = (response: any): void => {
			if (response.ok) {
				switch (response.script) {
					case 'F5':
						updateAllBrowsers();
						break;
					case 'CLOSE_SESSION':
						history(routes[routesIdMap.Login].path);
						break;
					case 'DELETE_BROWSER_CACHES':
						removeBrowserCache();
						setTimeout(() => {
							window.location.reload();
							window.location.reload();
						}, 0);
						break;
					default:
						break;
				}
			}
		};

		if (hubConnection) {
			hubConnection.addListener('onLaunchScript', launchScript);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hubConnection]);

	const isDevelopment = !isProduction;

	return (
		<div className="tw-app">
			{/* Page Loader */}
			<FullPageLoader icon={loader.icon} isVisible={Boolean(loader.isVisible)} message={loader.message} />

			{/* Header */}
			<AppHeader
				isDevelopment={isDevelopment}
				locale={locale}
				menuItems={menuItems}
				mobileSidebarOpen={mobileSidebarOpen}
				partner={partner as IPartner}
				setLocale={(loc) => setLocale(loc)}
				setMobileSidebarOpen={setMobileSidebarOpen}
				sidebarOpen={sidebarOpen}
			/>

			<section className="tw-app-body">
				{/* Sidebar */}
				<Sidebar
					AnchorComponent={NavLink}
					className={isDevelopment ? 'tw-app-sidebar-dev' : 'tw-app-sidebar'}
					menuItems={menuItems}
					setSidebarOpen={setSidebarOpen}
					sidebarOpen={sidebarOpen}
				/>
				{/* Main */}
				<main
					className={classNames('tw-main', {
						'tw-open-sidebar': sidebarOpen,
						dev: isDevelopment,
					})}
				>
					<Container className="tw-px-0 sm:tw-px-8" fluid>
						<Outlet />
					</Container>
				</main>
			</section>
			<Footer />
		</div>
	);
};

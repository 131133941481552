import React from 'react';

import { PermissionsType } from '@crac/core/models/types/PermissionsType';

import type { IRouteConfigType } from '~/types/RouteConfigType';

import { routesIdMap } from './routesIdMap';

const Cards = React.lazy(() => import('~/pages/Cards'));

export const cardsRoutes: { [key: number]: IRouteConfigType } = {};

cardsRoutes[routesIdMap.Cards] = {
	component: Cards,
	exact: true,
	id: routesIdMap.Cards,
	name: 'Cards',
	path: '/cards',
	permissions: [PermissionsType.BookingNew],
};

/**
 * @deprecated Use `ExtraDriverEndpoints` from `modules/booking/extraDriver/services`
 */
export enum ExtraDriverEndPoints {
	/**
	 * @host `/extradriver/getbybooking/`
	 */
	GET_BY_BOOKING = '/extradriver/getbybooking/',

	/**
	 * @host `/extradriver/getPrevisionServices/`
	 */
	GET_PREVISION_SERVICES = '/extradriver/getPrevisionServices/',

	/**
	 * @host `/extradriver/save/`
	 */
	SAVE = '/extradriver/save/',

	/**
	 * @host `/extradriver/remove/`
	 */
	REMOVE = '/extradriver/remove/',

	/**
	 * @host `/extradriver/getServices/`
	 */
	GET_SERVICES = '/extradriver/getServices/',
}

import { BookingLineEndPoints } from './BookingLineEndPoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';

export interface IBookingLineDeleteParams extends IServiceBaseParams {
	automatic: boolean;
	bookingNumber: string;
	key: number;
}

export const bookingLineDeleteService = (params: IBookingLineDeleteParams) => {
	return Api.post<number, IBookingLineDeleteParams>(BookingLineEndPoints.DELETE, params);
};

import { BookingLineEndPoints } from './BookingLineEndPoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IBookingLine } from '../entities/BookingLine';

export interface IBookingLineModifyAmountParams {
	bookingLineType: number;
	bookingNumber: string;
	code: string;
	invoiceTo: string;
	invoiceToAgency: boolean;
	key: number;
	price?: number;
}

export interface IBookingLineListModifyAmountParams extends IServiceBaseParams {
	bookingLineList: IBookingLineModifyAmountParams[];
}

export const bookingLineModifyAmountService = (params: IBookingLineListModifyAmountParams) => {
	return Api.post<IBookingLine[], IBookingLineListModifyAmountParams>(BookingLineEndPoints.MODIFY_AMOUNT, params);
};

import { BookingLineEndPoints } from './BookingLineEndPoints';
import type { InvoiceToCustomerType } from '../../../../models/types/InvoiceToCustomerType';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IBookingLine } from '../entities/BookingLine';
import type { BookingLineType } from '../types/BookingLineType';

export interface IBookingLineModifyParams {
	bookingLineType: BookingLineType;
	bookingNumber: string;
	code: string;
	invoiceTo: string;
	invoiceToAgency: boolean;
	invoiceToCustomerType: InvoiceToCustomerType;
	key: number;
	price?: number;
}

export interface IBookingLineListModifyParams extends IServiceBaseParams {
	bookingLineList: IBookingLineModifyParams[];
}

export const bookingLineModifyService = (params: IBookingLineListModifyParams) => {
	return Api.post<IBookingLine[], IBookingLineListModifyParams>(BookingLineEndPoints.MODIFY, params);
};

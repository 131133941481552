import React from 'react';

const breakpoints = {
	sm: '576px',
	md: '768px',
	lg: '992px',
	xl: '1200px',
} as const;

type Breakpoints = keyof typeof breakpoints;

export const useMediaQuery = (query: Breakpoints) => {
	const [matches, setMatches] = React.useState(false);

	React.useLayoutEffect(() => {
		const mediaQuery = window.matchMedia(`(max-width: ${breakpoints[query]})`);
		setMatches(mediaQuery.matches);

		mediaQuery.addEventListener('change', (event) => {
			setMatches(event.matches);
		});

		return () =>
			mediaQuery.removeEventListener('change', (event) => {
				setMatches(event.matches);
			});
	}, [query]);

	return matches;
};

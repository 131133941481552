import { FineEndPoints } from './FineEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IFine } from '../entities/Fine';

export interface IFineSearchParams extends IServiceBaseParams {
	provider: string;
	code?: string;
	bookingNumber?: string;
	plateNumber?: string;
	expedient?: string;
	fineReason?: number;
	state?: number;
	branch?: number;
	initialDate?: string | Date;
	finalDate?: string | Date;
}

/**
 * Search a fine
 * @return {Promise<FineType>} `FineType`
 */
export const fineSearchService = (model: IFineSearchParams): Promise<ServiceResponse<IFine[]>> => {
	return Api.get<IFine[], IFineSearchParams>(FineEndPoints.SEARCH, model);
};

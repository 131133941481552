export enum PermissionsType {
	// Can access rac page
	Access = 'Access',
	// Can access booking section
	Booking = 'Booking',
	// Can see booking detail
	BookingDetail = 'BookingDetail',
	// Can search booking
	BookingSearch = 'BookingSearch',
	// Can make a new booking with own agency or vendors
	BookingNew = 'BookingNew',
	// Can make a new booking with external agency or company
	BookingNewExternal = 'BookingNewExternal',
	// Can cancel an own booking
	BookingCancel = 'BookingCancel',
	// Can cancel an external booking // TODO: Verificar
	BookingCancelAgency = 'BookingCancelAgency',
	// Can modify a booking in Draft or Confirmed
	BookingModify = 'BookingModify',
	// Can make changes of a booking started (Processing, On_Hire)
	BookingModifyStarted = 'BookingModifyStarted',
	// Can add services to a booking
	BookingAddService = 'BookingAddService',
	// Can add damages to a booking
	BookingAddDamage = 'BookingAddDamage',
	// Can add franchise to a booking
	BookingAddFranchise = 'BookingAddFranchise',
	// Can remove franchiseLine only in on hire state
	FranchiseLineRemove = 'FranchiseLineRemove',
	// Can remove franchiseLine in all state
	FranchiseLineRemoveForEver = 'FranchiseLineRemoveForEver',
	// Can change booking to confirmed
	BookingChangeState = 'BookingChangeState',
	// Can add payment line to a booking
	BookingAddPaymentLine = 'BookingAddPaymentLine',
	// Can send booking voucher to customer
	BookingSendToCustomer = 'BookingSendToCustomer',
	// Can send booking voucher to agency/company of a booking
	BookingSendToAgencyCompany = 'BookingSendToAgencyCompany',
	// Can change invoice to agency/company of a booking
	BookingChangeInvoiceToAgencyCompany = 'BookingChangeInvoiceToAgencyCompany',
	// Can change invoice to customer of a booking
	BookingChangeInvoiceToCustomer = 'BookingChangeInvoiceToCustomer',
	BookingViewInvoiceToCompany = 'BookingViewInvoiceToCompany',
	// Can do a change vehicle of a booking
	BookingChangeVehicle = 'BookingChangeVehicle',
	// Can change a customer of a booking
	BookingModifyCustomer = 'BookingModifyCustomer',
	// Can view booking pick up
	BookingPickUp = 'BookingPickUp',
	// Can do booking pick up
	BookingPickUpProcess = 'BookingPickUpProcess',
	// Can view booking drop offs
	BookingDropOff = 'BookingDropOff',
	// Can do booking drop off
	BookingDropOffProcess = 'BookingDropOffProcess',
	// Can remove comments from a booking
	BookingRemoveComment = 'BookingRemoveComment',
	// Can access turns sections
	BookingTurns = 'BookingTurns',
	// Can access meeting turns sections
	BookingTurnsMeeting = 'BookingTurnsMeeting',
	// Can access bus turns sections
	BookingTurnsBus = 'BookingTurnsBus',
	// Can access front desk turns sections
	BookingTurnsFrontDesk = 'BookingTurnsFrontDesk',
	// Can access booking regularization section
	BookingRegularization = 'BookingRegularization',
	// Can make a new booking regularization
	BookingRegularizationNew = 'BookingRegularizationNew',
	// Can accept or reject a booking regularization
	BookingRegularizationAcceptReject = 'BookingRegularizationAcceptReject',
	// Can close a booking regularization
	BookingRegularizationClose = 'BookingRegularizationClose',

	// Can access customer section
	Customer = 'Customer',
	// Can view customer detail
	CustomerDetail = 'CustomerDetail',
	// Can search customer
	CustomerSearch = 'CustomerSearch',
	// Can change customer type
	CustomerChangeType = 'CustomerChangeType',
	// Can change customer type to gold
	CustomerChangeTypeGold = 'CustomerChangeTypeGold',
	// Can change customer type to guia
	CustomerChangeTypeGuia = 'CustomerChangeTypeGuia',
	// Can change customer type to transfer
	CustomerChangeTypeTransfer = 'CustomerChangeTypeTransfer',
	// Can change customer type to staff
	CustomerChangeTypeStaff = 'CustomerChangeTypeStaff',
	// Can change customer points
	CustomerChangePoints = 'CustomerChangePoints',
	// Can send customer reset password
	CustomerResetPassword = 'CustomerResetPassword',
	// Can send customer coupon
	CustomerSendCoupon = 'CustomerSendCoupon',
	// Can modify customer
	CustomerModify = 'CustomerModify',
	// Can make new customer
	CustomerNew = 'CustomerNew',
	CustomerRemove = 'CustomerRemove',
	// Can remove customer comments
	CustomerRemoveComment = 'CustomerRemoveComment',

	// Can access company section
	Company = 'Company',
	// Can view company detail
	CompanyDetail = 'CompanyDetail',
	// Can make new company
	CompanyNew = 'CompanyNew',
	// Can modify company
	CompanyModify = 'CompanyModify',
	// Can remove company comments
	CompanyRemoveComment = 'CompanyRemoveComment',

	// Can access vehicle section
	Vehicle = 'Vehicle',
	// Can view vehicle detail
	VehicleDetail = 'VehicleDetail',
	// Can search vehicle
	VehicleSearch = 'VehicleSearch',
	// Can add damage to a vehicle
	VehicleAddDamage = 'VehicleAddDamage',
	// Can repair a damage from a vehicle
	VehicleRepairDamage = 'VehicleRepairDamage',
	// Can change state from a vehicle
	VehicleChangeState = 'VehicleChangeState',
	// Can change OBE from a vehicle
	VehicleChangeOBE = 'VehicleChangeOBE',
	// Can add expedient to a vehicle
	VehicleAddExpedient = 'VehicleAddExpedient',
	// Can access reception of a vehicle
	VehicleReception = 'VehicleReception',
	// Can access return of a vehicle
	VehicleReturn = 'VehicleReturn',
	// Can access preparation of a vehicle
	VehiclePreparation = 'VehiclePreparation',
	// Can access revision of a vehicle
	VehicleRevision = 'VehicleRevision',
	// Can do ket chains labels
	VehicleKeyChainsLabels = 'VehicleKeyChainsLabels',
	// Can remove comment of a vehicle
	VehicleRemoveComment = 'VehicleRemoveComment',
	// Change anytime vehicle km
	VehicleChangeKM = 'VehicleChangeKM',
	// Can blocked vehicle
	VehicleBlock = 'VehicleBlock',
	// Can access management section
	Management = 'Management',
	// Can access cash line section
	ManagementCashLine = 'ManagementCashLine',
	// Can sign pending cash line
	ManagementCashLineSign = 'ManagementCashLineSign',
	// Can access computers section
	ManagementComputers = 'ManagementComputers',
	// Can access queues section
	ManagementQueues = 'ManagementQueues',
	// Opción de marcar/desmarcar el campo (allowRegisterWithPending) de la reserva.
	BookingChangeAllowPendingsInContract = 'BookingChangeAllowPendingsInContract',
	Commissions = 'Commissions',
	PaymentLineSearch = 'PaymentLineSearch',
	// Utilizado para habilitar web payment in contract
	BookingReferencePaymentInContract = 'BookingReferencePaymentInContract',
	// Update paymentLine carge back
	PaymentLineUpdateChargeBack = 'PaymentLineUpdateChargeBack',
	// Can view paymentLine charge back
	PaymentLineViewChargeBack = 'PaymentLineViewChargeBack',
	VehicleSearchAll = 'VehicleSearchAll',
	// Devolución de anticipos en estado no show
	BookingRefundPayment = 'BookingRefundPayment',
	PaymentLineCustomer = 'PaymentLineCustomer',
	// Can view lockers
	LockerView = 'LockerView',
	// Can access locker management
	LockerManagement = 'LockerManagement',
	// Can open locker
	LockerOpen = 'LockerOpen',
	// Can open vehicle
	VehicleOpen = 'VehicleOpen',
	// Can use CF agency
	BookingCentauroFree = 'BookingCentauroFree',
	/**
	 * Can view partner amnounts
	 */
	ViewPartnerAmounts = 'ViewPartnerAmounts',
	/**
	 * All access to rates
	 */
	Pricing = 'Pricing',

	PricingRentRate = 'PricingRentRate',

	PricingRentRateType = 'PricingRentRateType',

	PricingRentRateFormula = 'PricingRentRateFormula',

	PricingRentRateVariation = 'PricingRentRateVariation',
	// ALL PERMISSIONS
	IT = 'IT',
	// Can extend booking
	BookingCanExtend = 'BookingCanExtend',
	// Vehicle Group Availability configuration
	PricingAvailabilityConfiguration = 'PricingAvailabilityConfiguration',
	// Vehicle Group Order and Upgrade
	PricingVehicleGroupOrderAndUpgrade = 'PricingVehicleGroupOrderAndUpgrade',
	// Vehicle Group Included Configuration
	PricingVehicleGroupIncludedConfiguration = 'PricingChoosableVehicleGroupConfiguration',

	// Service Rates
	PricingServiceRate = 'PricingServiceRate',
	// Service Rate Types
	PricingServiceRateType = 'PricingServiceRateType',
	// Occupancy Configuration
	PricingOccupancyConfiguration = 'PricingOccupancyConfiguration',
	// Discount coupon
	PricingDiscountCoupon = 'PricingDiscountCoupon',
	// Km rates
	PricingKmRate = 'PricingKmRate',
	// Deposit rate
	PricingDepositRate = 'PricingDepositRate',
	// Preauthorization rate
	PricingPreauthorizationRate = 'PricingPreauthorizationRate',
	// Maximum booking duration
	PricingMaximumBookingDuration = 'PricingMaximumBookingDuration',
	// Excess rates
	PricingMaximumExcessRate = 'PricingMaximumExcessRate',
	// One Way
	PricingOneWay = 'PricingOneWay',
	// Stop Sales
	PricingStopSale = 'PricingStopSale',
	// Pricing Not Allowed Movement Between Zones
	PricingNotAllowedMovementBetweenZones = 'PricingNotAllowedMovementBetweenZones',
	// Pricing rent rate margin
	PricingRentRateMargin = 'PricingRentRateMargin',
	// Pricing Branch Scheduled
	PricingBranchSchedule = 'PricingBranchSchedule',
	// Pricing Rent Rate Validations
	PricingRentRateValidation = 'PricingRentRateValidations',
	// Pricing Rent Rate Type Relation
	PricingRentRateTypeRelation = 'PricingRentRateTypeRelation',
	// Pricing Package Configuration
	PricingPackageConfiguration = 'PricingPackageConfiguration',
	// Partner Category
	PricingPartnerCategory = 'PricingPartnerCategory',
	// Partner Group
	PricingPartnerGroup = 'PricingPartnerGroup',
	// Permiso de acceso al área de flotas
	Fleet = 'Fleet',
	// Fleet fines
	Fine = 'Fine',
	FineManagement = 'FineManagement',
	FineReason = 'FineReason',
	FineTransmitter = 'FineTransmitter',
	// Fleet transfer
	FleetTransfer = 'FleetTransfer',
	PricingInvoiceableServiceConfiguration = 'PricingInvoiceableServiceConfiguration',
	PricingIncludedServiceConfiguration = 'PricingIncludedServiceConfiguration',
	PricingChoosableServiceConfiguration = 'PricingChoosableServiceConfiguration',
	PricingCouponConfiguration = 'PricingCouponApplicationConfiguration',
	// Movement Restriction
	VehicleManageRestrictionMovement = 'VehicleManageRestrictionMovement',
	// Fleet vehicle model
	FleetVehicleModel = 'FleetVehicleModel',
	// Fleet vehicle brand
	FleetVehicleBrand = 'FleetVehicleBrand',
	// Fleet vehicle color
	FleetVehicleColor = 'FleetVehicleColor',
	// Fleet previous registration
	VehiclePreviousRegistrationManagement = 'VehiclePreviousRegistrationManagement',
	// Fleet vehicle version
	VehicleVersion = 'VehicleVersion',
	VehicleVersionManagement = 'VehicleVersionManagement',

	VehicleEngine = 'VehicleEngine',
	VehicleEngineManagement = 'VehicleEngineManagement',

	ExcessRate = 'ExcessRate',
	ExcessType = 'ExcessType',

	// Fleet vehicle group - search
	FleetVehicleGroup = 'FleetVehicleGroup',
	// Fleet vehicle group - insert, modify, delete
	FleetVehicleGroupManage = 'FleetVehicleGroupManage',
	// Fleet Prevision
	FleetPrevision = 'FleetPrevision',
	// Fleet Workshop assignment
	FleetWorkshopAssignment = 'FleetWorkshopAssignment',
	FleetBrandParking = 'BrandParking',
	Sale = 'Sale',
	// SaleMargin
	SaleMargin = 'SaleMargin',
	// SaleOrder
	SaleOrder = 'SaleOrder',
	// SaleService
	SaleService = 'SaleService',
	// SaleOrderInsertB2B
	SaleOrderInsertB2B = 'SaleOrderInsertB2B',
	// SaleOrderInsertB2C
	SaleOrderInsertB2C = 'SaleOrderInsertB2C',
	// Sale Order Confirm Deposit Received
	SaleOrderConfirmDepositReceived = 'SaleOrderConfirmDepositReceived',
	// Sale Order Start Reconditioning
	SaleOrderStartReconditioning = 'SaleOrderStartReconditioning',
	// Sale Order Accept Reconditioning
	SaleOrderAcceptReconditioning = 'SaleOrderAcceptReconditioning',
	// Sale Order Confirm Reconditioning
	SaleOrderConfirmReconditioning = 'SaleOrderConfirmReconditioning',
	// Sale Order Confirm Payment Received
	SaleOrderConfirmPaymentReceived = 'SaleOrderConfirmPaymentReceived',
	// Sale Order Confirm Change Of Ownership
	SaleOrderConfirmChangeOfOwnership = 'SaleOrderConfirmChangeOfOwnership',
	// Sale Order Confirm Documentation Delivery
	SaleOrderConfirmDocumentationDelivery = 'SaleOrderConfirmDocumentationDelivery',
	// Sale Order Confirm Keys Delivery
	SaleOrderConfirmKeysDelivery = 'SaleOrderConfirmKeysDelivery',
	// Sale Order Cancel
	SaleOrderCancel = 'SaleOrderCancel',
	// Sale Order Change Amounts
	SaleOrderChangeAmounts = 'SaleOrderChangeAmounts',
	// Sale Order Confirm Delivery
	SaleOrderConfirmDelivery = 'SaleOrderConfirmDelivery',
	// Sale Order Confirm Price
	SaleOrderConfirmPrice = 'SaleOrderConfirmPrice',
	// Sale Order Remove Unsold
	SaleOrderRemoveUnsold = 'SaleOrderRemoveUnsold',
	// Sale Order Set Block To Sale
	SaleOrderSetBlockToSale = 'SaleOrderSetBlockToSale',
	// Sale Order Confirm Appointment
	SaleOrderConfirmAppointment = 'SaleOrderConfirmAppointment',
	// Sale Order Confirm Customer
	SaleOrderConfirmCustomer = 'SaleOrderConfirmCustomer',
	// Sale Vehicle Model
	SaleVehicleModel = 'SaleVehicleModel',
	// Accident Management
	AccidentManagement = 'AccidentManagement',
	// Insurance Rate
	InsuranceRate = 'Insurance',
	// Revision Type
	RevisionType = 'RevisionType',
	// Revision Cost
	RevisionCost = 'RevisionCost',
	// Revision Line
	RevisionLine = 'RevisionLine',
	// Damage repair
	DamageRepairCost = 'DamageRepairCost',

	CanAddDamageWithoutPhoto = 'CanAddDamageWithoutPhoto',
	// Complete vehicle data in change state
	VehicleAutoCompleteData = 'VehicleAutoCompleteData',
	// Vehicle detail
	VehicleUpdateKeysLocation = 'VehicleUpdateKeysLocation',
	VehicleAssignTag = 'VehicleAssignTag',
	VehicleChangeLastRevisionKM = 'VehicleChangeLastRevisionKM',
	VehicleTelemetry = 'VehicleTelemetry',
	VehicleCloseOnHire = 'VehicleCloseOnHire',
	/**
	 * To access MetaModel's section
	 */
	MetaModel = 'MetaModel',
	/**
	 * To be able to insert/update a meta model
	 */
	MetaModelManagement = 'MetaModelManagement',
	/**
	 * To be able to insert/update a meta model's image
	 */
	MetaModelAddImage = 'MetaModelAddImage',
	/**
	 * Use to show button to download revision invoice
	 */
	RevisionInvoice = 'RevisionInvoice',
	/**
	 * Load present for fitur locker
	 */
	LockerLoadPresents = 'LockerLoadPresents',
	/**
	 * Change the group of the vehicle
	 */
	VehicleChangeGroup = 'VehicleChangeGroup',
	/**
	 * Change the model of the vehicle
	 */
	VehicleChangeModel = 'VehicleChangeModel',
	/**
	 * Change the expected defleet date of the vehicle
	 */
	VehicleChangeExpectedDefleetDate = 'VehicleChangeExpectedDefleetDate',
	/**
	 * Change the agreed repurchase date of the vehicle
	 */
	VehicleChangeAgreedRepurchaseDate = 'VehicleChangeAgreedRepurchaseDate',
	/**
	 * Change the next ITV date of the vehicle
	 */
	VehicleChangeNextITVDate = 'VehicleChangeNextITVDate',
	/**
	 * Change the rent start date of the vehicle
	 */
	VehicleChangeRentStartDate = 'VehicleChangeRentStartDate',
	/**
	 * Change the rent end date of the vehicle
	 */
	VehicleChangeRentEndDate = 'VehicleChangeRentEndDate',
	/**
	 * Change the unregister traffic date of the vehicle
	 */
	VehicleChangeUnregisterTrafficDate = 'VehicleChangeUnregisterTrafficDate',
	/**
	 * Change the fleet typology of the vehicle
	 */
	VehicleChangeFleetTypology = 'VehicleChangeFleetTypology',
	/**
	 * Change the amortization end date of the vehicle
	 */
	VehicleChangeAmortizationEndDate = 'VehicleChangeAmortizationEndDate',
	/**
	 * Change the insurance company of the vehicle
	 */
	VehicleChangeInsuranceCompany = 'VehicleChangeInsuranceCompany',
	/**
	 * Change the carrier of the vehicle
	 */
	VehicleChangeCarrier = 'VehicleChangeCarrier',
	/**
	 * Change the registration date of the vehicle
	 */
	VehicleChangeRegistrationDate = 'VehicleChangeRegistrationDate',
	/**
	 * Change the sale amount of the vehicle
	 */
	VehicleChangeSaleAmount = 'VehicleChangeSaleAmount',
	/**
	 * Change the brand parking of the vehicle
	 */
	VehicleChangeBrandParking = 'VehicleChangeBrandParking',
	/**
	 * Change the plate number of the vehicle
	 */
	VehicleChangePlateNumber = 'VehicleChangePlateNumber',
	/**
	 * Request insurance cancellation for the vehicle
	 */
	VehicleRequestInsuranceCancelation = 'VehicleRequestInsuranceCancelation',
}

export enum PermissionsEntity {
	Management = 'Management',
	Booking = 'Booking',
	Company = 'Company',
	Customer = 'Customer',
	Vehicle = 'Vehicle',
	Pricing = 'Pricing',
	Fleet = 'Fleet',
}

import type { IDocument } from '../../models/entities/Document';
import type { IDocumentGetByBookingParams } from '../../models/serviceParams/DocumentsParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { DocumentEndPoints } from '../endPoints/DocumentEndPoints';

/**
 * @deprecated - use `documentGetByBookingService` from 'packages\core\src\modules\documentation\services\DocumentGetByBookingService.ts' instead.
 * Get documents by booking number
 *  {string} bookingNumber Booking number
 * @return {Promise} `DocumentPropType` List document
 */
export const documentServiceGetByBooking = (
	model: IDocumentGetByBookingParams,
): Promise<ServiceResponse<IDocument[]>> => {
	return Api.get<IDocument[], IDocumentGetByBookingParams>(DocumentEndPoints.GET_BY_BOOKING, model);
};
